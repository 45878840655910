define("comorbidity/services/icd10", ["exports", "@ember/service", "comorbidity/helpers/pathology-detection"], function (_exports, _service, _pathologyDetection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // Use full resources:
  // - http://taurus.unine.ch/icd10
  // - https://icd.who.int/icdapi
  class ICD10Service extends _service.default {
    constructor() {
      super(...arguments);
      // If we ever happen to use the ICD API, this will be replaced by the content returned by the API
      _defineProperty(this, "release2008", {
        // Cardiovascular, Neurologic, etc... are not valid ICD 10 block codes
        // TODO: scatter these codes in their respective blocks
        Cardiovascular: ["I10", "I24.9", "I11", "Z95.0", "I73.9", "I71.4", "I70", "I21", "I77.0", "I81"],
        Neurologic: ["G45.9", "I64", "G20", "F03", "G30", "G35", "G12.2"],
        Endocrine: ["E10", "E11", "E03.9", "E05", "E66"],
        NeoplasticAntecedents: ["C50", "C18", "C34", "C44", "C64", "C67", "C61", "C95", "C22.0", "C71"],
        Respiratory: ["J42", "J45", "G47.3", "J47", "Z99.1", "E84.0"],
        Digestive: ["K27", "K52.9", "B16", "B18.2", "K76.9", "B15.9", "K83.0", "K51", "B17.0", "K74", "K900"],
        Urologic: ["N40", "Q62.1", "Q62.7", "N28.1", "N18.4", "N18.5", "N18.0", "N18.9"],
        Osteo: ["M81.9", "M19.9", "T14.2"],
        // The following lines are Valid ICD 10 block codes
        "I30-I52": ["I35.0", "I48", "I49.9", "I50.9", "I51.6"],
        "E70-E90": ["E78", "E78.5", "E88.9", "E78.9"],
        "F10-F19": ["F10", "F12", "F17", "F17.24", "F10.24"],
        "F30-F39": ["F33", "F34"],
        "F40-F48": ["F40.0", "F40.1", "F40.2", "F41.0", "F41.1", "F43.1", "F42.9"],
        "F90-F98": ["F90.0"],
        "Z00-Z99": ["Z72.3"],
        "L00-L99": ["L85.8", "L93"],
        "A00-B99": ["B02"],
        "I00-I99": ["I24"],
        "D50-D89": ["D86"],
        "G89-G99": ["G99.0"],
        "H30-H36": ["H36"],
        other: ["other"]
      });
    }
    // The block codes are in the form A00-A09
    // In the ICD-10 data structure, they are children to chapter codes that we do not use in this service
    get blockCodes() {
      return Object.keys(this.release2008);
    }

    // Category codes are codes that represent a comorbidity
    // A category code has children. Each child is also a category code
    get categoryCodes() {
      const reducer = (acc, _ref) => {
        let [key, value] = _ref;
        acc.push(key);
        if (value instanceof Array) return [...acc, ...value];
        acc.push(value);
        return acc;
      };
      return Object.entries(this.release2008).reduce(reducer, []);
    }
    hasCode(code) {
      return this.blockCodes.includes(code) || this.categoryCodes.includes(code);
    }

    /**
     * This method handle an algorithm able to detect some comorbidities
     * with data from ALDi (Cim-10 code) and/or DMP HR
     */
    detectComorbidities(patient) {
      return (0, _pathologyDetection.default)(patient);
    }

    /**
     * @param followUpReason The patient's follow up reason
     * @returns A list of suggested ICD10 codes
     */
    suggestedIcd10ForFur(followUpReason) {
      if (["generic-general", "nephro-transplant", "nephro-mrc", "cardio-transplant", "cardio-heart-failure", "hepato-transplant"].includes(followUpReason)) {
        return ["E10", "E11", "I10", "I64"];
      }
      if (followUpReason == "diabeto-dt2") {
        return ["H36", "G99.0", "N18.9", "I51.6", "I10", "E78.9", "I50.9", "other"];
      }
      if (["gastro-crohn", "gastro-rch"].includes(followUpReason)) {
        return ["E11", "I10", "I64"];
      }

      /**
       * Returns an empty array for other FuRs for now
       * Will later be set for each FuR
       */
      return [];
    }
  }
  _exports.default = ICD10Service;
});