define("comorbidity/components/comorbidity/pathology-section-content/index", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! @glint-expect-error }}
  {{#let @patient.comorbiditiesAndAllergiesChangedJournalEvent as |je|}}
    {{#if (gt je.comorbidities.length 0)}}
      <ul data-test-list-comorbidities class="ui list">
        {{#each je.comorbidities as |comorbidity index|}}
          <li data-test-comorbidity={{index}}>{{icd10-title comorbidity}}</li>
        {{/each}}
      </ul>
    {{else}}
      <div class="placeholder-label" data-test-default-pathologies>
        {{t "dashboard.medical-synthesis.pathologies-default-message"}}
      </div>
    {{/if}}
  {{/let}}
  */
  {
    "id": "NpLfYH+F",
    "block": "[[[44,[[30,1,[\"comorbiditiesAndAllergiesChangedJournalEvent\"]]],[[[41,[28,[37,2],[[30,2,[\"comorbidities\",\"length\"]],0],null],[[[1,\"    \"],[10,\"ul\"],[14,\"data-test-list-comorbidities\",\"\"],[14,0,\"ui list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2,[\"comorbidities\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,\"data-test-comorbidity\",[30,4]],[12],[1,[28,[35,5],[[30,3]],null]],[13],[1,\"\\n\"]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"placeholder-label\"],[14,\"data-test-default-pathologies\",\"\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"dashboard.medical-synthesis.pathologies-default-message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[2]]]],[\"@patient\",\"je\",\"comorbidity\",\"index\"],false,[\"let\",\"if\",\"gt\",\"each\",\"-track-array\",\"icd10-title\",\"t\"]]",
    "moduleName": "comorbidity/components/comorbidity/pathology-section-content/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});