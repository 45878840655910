define("comorbidity/components/comorbidity/pathology-detection/index", ["exports", "@ember/component", "@ember/object", "@ember/service", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (gt this.detectedPathologies.length 0)}}
    <div
      class="ui segment show-shyone-on-hover"
      data-test-pathology-detection-section
    >
      <h5>Les pathologies suivantes ont été détectées à partir des données
        disponibles</h5>
      <ul class="ui list">
        {{#each this.detectedPathologies as |pathology|}}
          <li data-test-illness-detected={{pathology}}>
            {{t (concat "comorbidity.icd10." pathology)}}
  
            <span data-tooltip="Ajouter aux comorbidités">
              <LinkButton
                class="item shyone"
                data-test-add-illness={{pathology}}
                {{on "click" (fn this.addComorbidity pathology)}}
              >
                <i class="ui add icon"></i>
              </LinkButton>
            </span>
            <span data-tooltip="Ignorer">
              <LinkButton
                class="item shyone"
                data-test-ignore-illness={{pathology}}
                {{on "click" (fn this.ignoreComorbidity pathology)}}
              >
                <i class="ui times icon"></i>
              </LinkButton>
            </span>
            {{! TODO:
            <a class="item shyone" role="button">
              <i class="ui info icon" />
            </a>
            }}
          </li>
        {{/each}}
      </ul>
      <button
        type="button"
        class="ui button"
        data-test-add-all-illness
        {{on "click" this.addComorbidities}}
      >
        Ajouter toutes les pathologies détectées
      </button>
    </div>
  {{/if}}
  */
  {
    "id": "754swoxp",
    "block": "[[[41,[28,[37,1],[[30,0,[\"detectedPathologies\",\"length\"]],0],null],[[[1,\"  \"],[10,0],[14,0,\"ui segment show-shyone-on-hover\"],[14,\"data-test-pathology-detection-section\",\"\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Les pathologies suivantes ont été détectées à partir des données\\n      disponibles\"],[13],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"ui list\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"detectedPathologies\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,\"data-test-illness-detected\",[30,1]],[12],[1,\"\\n          \"],[1,[28,[35,4],[[28,[37,5],[\"comorbidity.icd10.\",[30,1]],null]],null]],[1,\"\\n\\n          \"],[10,1],[14,\"data-tooltip\",\"Ajouter aux comorbidités\"],[12],[1,\"\\n            \"],[8,[39,6],[[24,0,\"item shyone\"],[16,\"data-test-add-illness\",[30,1]],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"addComorbidity\"]],[30,1]],null]],null]],null,[[\"default\"],[[[[1,\"\\n              \"],[10,\"i\"],[14,0,\"ui add icon\"],[12],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,\"data-tooltip\",\"Ignorer\"],[12],[1,\"\\n            \"],[8,[39,6],[[24,0,\"item shyone\"],[16,\"data-test-ignore-illness\",[30,1]],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"ignoreComorbidity\"]],[30,1]],null]],null]],null,[[\"default\"],[[[[1,\"\\n              \"],[10,\"i\"],[14,0,\"ui times icon\"],[12],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"],[1,\"        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"ui button\"],[24,\"data-test-add-all-illness\",\"\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"addComorbidities\"]]],null],[12],[1,\"\\n      Ajouter toutes les pathologies détectées\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"pathology\"],false,[\"if\",\"gt\",\"each\",\"-track-array\",\"t\",\"concat\",\"link-button\",\"on\",\"fn\"]]",
    "moduleName": "comorbidity/components/comorbidity/pathology-detection/index.hbs",
    "isStrictMode": false
  });
  const currentComorbChangesetKey = "comorbidities";
  const ignoredComorbChangesetKey = "ignoredComorbidities";
  let PathologyDetectionComponent = _exports.default = (_class = class PathologyDetectionComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "icd10", _descriptor, this);
    }
    get patient() {
      return this.args.patient;
    }
    get changeset() {
      return this.args.changeset;
    }
    get currentSelectedComorbidities() {
      return this.changeset.get(currentComorbChangesetKey) || [];
    }
    get currentIgnoredComorbidities() {
      return this.changeset.get(ignoredComorbChangesetKey) || [];
    }

    /**
     * Return the comorbidity detected that are not already in the selected comorbidities,
     * as an array of icd10 code
     */
    get detectedPathologies() {
      return this.icd10.detectComorbidities(this.patient).map(patho => patho.code).filter(code => {
        return !this.currentSelectedComorbidities.includes(code) && !this.currentIgnoredComorbidities.includes(code);
      });
    }
    addComorbidity(code) {
      this.changeset.set(currentComorbChangesetKey, [...this.currentSelectedComorbidities, code]);
    }
    ignoreComorbidity(code) {
      this.changeset.set(ignoredComorbChangesetKey, [...this.currentIgnoredComorbidities, code]);
    }
    addComorbidities() {
      this.changeset.set(currentComorbChangesetKey, [...this.currentSelectedComorbidities, ...this.detectedPathologies]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "icd10", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addComorbidity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addComorbidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreComorbidity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreComorbidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addComorbidities", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addComorbidities"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PathologyDetectionComponent);
});