define("comorbidity/helpers/pathology-detection", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class PathologyDetector {
    /** returns an array containing objects with icd10 codes */
    get pathologies() {
      return this.pathologyCodes.map(code => ({
        code
      }));
    }

    /**
     * @param pathologyCodes - An array containing icd10 codes
     * @param conditionFunc - A function returning a boolean and taking a patient as parameter
     */
    constructor(pathologyCodes, conditionFunc) {
      _defineProperty(this, "pathologyCodes", void 0);
      _defineProperty(this, "condition", void 0);
      this.pathologyCodes = pathologyCodes;
      this.condition = conditionFunc;
    }
    detect(params) {
      return this.condition(params);
    }
  }
  function _default(patient) {
    let pathologies = [];
    const aldRetrievedJournalEvents = patient.aldRetrievedJournalEvents;
    const drugsAndDevicesRefundedJournalEvents = patient.drugsAndDevicesRefundedJournalEvents;
    pathologyDetectors.forEach(detector => {
      if (detector.detect({
        drugsAndDevicesRefundedJournalEvents,
        aldRetrievedJournalEvents
      })) {
        pathologies = [...pathologies, ...detector.pathologies];
      }
    });
    return pathologies;
  }
  const pathologyDetectors = [
  /// DIABETE

  new PathologyDetector(["E10", "E11"], function (_ref) {
    let {
      drugsAndDevicesRefundedJournalEvents,
      aldRetrievedJournalEvents
    } = _ref;
    // Personnes ayant reçu au moins 2 délivrances (à différentes dates) parmi les codes ATC commençant par A10 à l'exception du code A10BX06
    let atcFoundDate = null;
    const hasDeliveries = drugsAndDevicesRefundedJournalEvents && drugsAndDevicesRefundedJournalEvents.some(je => {
      if (je.hasSourceValue({
        sourceValue: "A10",
        sourceConcept: "ATC",
        excludedValues: ["A10BX06"],
        startsWith: true
      })) {
        // If we found the code for the second time at a different day
        if (atcFoundDate && !(0, _dayjs.default)(atcFoundDate).isSame(je.occurredAt, "d")) {
          return true;
        }
        atcFoundDate = je.occurredAt;
      }
      return false;
    });
    if (!hasDeliveries) {
      if (!aldRetrievedJournalEvents) {
        return false;
      }

      // Personnes en ALD avec un code CIM-10 parmi les codes E10, E11, E12, E13, E14 (il faut que le code CIM_10 soit égal ou commence par ces codes)
      return hasAld(aldRetrievedJournalEvents, ["E10", "E11", "E12", "E13", "E14"], true);
    }
    return true;
  }),
  /// INSUFFISIANCE CARDIAQUE

  new PathologyDetector(["I50.9"], function (_ref2) {
    let {
      aldRetrievedJournalEvents
    } = _ref2;
    if (!aldRetrievedJournalEvents) {
      return false;
    }
    // Personnes en ALD avec un code CIM-10 parmi les codes I50, I11, I13 (il faut que le code CIM_10 soit égal ou commence par ces codes)
    return hasAld(aldRetrievedJournalEvents, ["I50", "I11", "I13"], true);
  }),
  /// OXYGENOTHERAPIE

  new PathologyDetector(["Z991"], function (_ref3) {
    let {
      drugsAndDevicesRefundedJournalEvents
    } = _ref3;
    if (!drugsAndDevicesRefundedJournalEvents) {
      return false;
    }
    // Personnes ayant au moins 1 code LPP parmi
    return hasDrugsRefounded({
      drugsAndDevicesRefundedJournalEvents,
      sourceValues: ["1100229", "1101950", "1103297", "1103720", "1105184", "1105416", "1105528", "1107579", "1107763", "1116680", "1116880", "1118324", "1118904", "1119789", "1119826", "1120338", "1121421", "1122053", "1123242", "1123609", "1125100", "1125287", "1125790", "1126401", "1126660", "1126708", "1128104", "1130220", "1130897", "1132270", "1132442", "1133163", "1133430", "1135392", "1136581", "1138315", "1143470", "1143983", "1145404", "1145723", "1146444", "1148130", "1149106", "1150635", "1158329", "1158737", "1162035", "1162093", "1162437", "1163951", "1165938", "1165967", "1166820", "1167890", "1167937", "1169304", "1171614", "1171790", "1172513", "1172967", "1174133", "1175380", "1178540", "1180524", "1184315", "1186685", "1188885", "1189991", "1190623", "1191568", "1199742"],
      sourceConcept: "LPP"
    });
  })];

  /**
   * @param codes - Contains icd10 codes
   * @param startsWith - Will search for exact code if false or a code which starts with the code if true
   */
  function hasAld(aldRetrievedJournalEvents, codes) {
    let startsWith = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return aldRetrievedJournalEvents.some(je => je.hasCode(codes, startsWith));
  }

  /**
   * @property excludedValues - An array with source values to exclude from the search
   * @property startsWith - Will search for exact code if false or a code which starts with the code if true
   */
  function hasDrugsRefounded(_ref4) {
    let {
      drugsAndDevicesRefundedJournalEvents,
      sourceValues,
      sourceConcept,
      excludedValues = [],
      startsWith = false
    } = _ref4;
    return drugsAndDevicesRefundedJournalEvents.some(je => je.hasSourceValue({
      sourceValue: sourceValues,
      sourceConcept,
      excludedValues,
      startsWith
    }));
  }
});