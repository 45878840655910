define("comorbidity/models/comorbidities-and-allergies-changed-journal-event", ["exports", "@ember-data/model", "core/models/journal-event", "ember-changeset-validations/validators"], function (_exports, _model, _journalEvent, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SemeiaComorbidityCode = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _ComorbiditiesAndAllergiesChangedJournalEvent;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  //---- Automatically generated, don't update this part ----
  let ComorbiditiesAndAllergiesChangedJournalEvent = _exports.default = (_dec = (0, _model.attr)({
    defaultValue() {
      return [];
    }
  }), _dec2 = (0, _model.attr)({
    defaultValue() {
      return [];
    }
  }), _dec3 = (0, _model.attr)("string"), _dec4 = (0, _model.belongsTo)("patient", {
    async: false,
    inverse: "comorbiditiesAndAllergiesChangedJournalEvent"
  }), (_class = (_ComorbiditiesAndAllergiesChangedJournalEvent = class ComorbiditiesAndAllergiesChangedJournalEvent extends _journalEvent.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "comorbidities", _descriptor, this);
      _initializerDefineProperty(this, "ignoredComorbidities", _descriptor2, this);
      _initializerDefineProperty(this, "medicatedAllergies", _descriptor3, this);
      //---- End of generated code ----
      _initializerDefineProperty(this, "patient", _descriptor4, this);
    }
    static validations(intl) {
      return {
        occurredAt: (0, _validators.validatePresence)({
          presence: true,
          description: intl.t("validations.occurred-at")
        })
      };
    }
    get hasComorbidities() {
      return this.comorbidities && this.comorbidities.length > 0;
    }
    get hasMedicatedAllergies() {
      return Boolean(this.medicatedAllergies);
    }
  }, _defineProperty(_ComorbiditiesAndAllergiesChangedJournalEvent, "type", "comorbidities-and-allergies-changed-journal-event"), _ComorbiditiesAndAllergiesChangedJournalEvent), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "comorbidities", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ignoredComorbidities", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "medicatedAllergies", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "patient", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  // class used to convert legacy semeia comorbidity codes to valid icd10 codes
  class SemeiaComorbidityCode {
    constructor(code) {
      this.code = code;
    }
    get isLegacy() {
      return Boolean(this.code.match(/^ICD-/));
    }
    get isICD10() {
      return !this.isLegacy;
    }
    get icd10Code() {
      if (this.isICD10) return this.code;

      // remove the starting ICD-
      // replace the "-" with a dot
      return this.code.replace(/^ICD-/, "").replace(/(\d)-(\d)/, "$1.$2");
    }
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your models.
  _exports.SemeiaComorbidityCode = SemeiaComorbidityCode;
});