define("comorbidity/components/comorbidity/edition-form/index", ["exports", "@ember/component", "@glimmer/component", "comorbidity/models/comorbidities-and-allergies-changed-journal-event", "@ember/template-factory"], function (_exports, _component, _component2, _comorbiditiesAndAllergiesChangedJournalEvent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Forc::Provider::Base @changeset={{@changeset}} as |Form|>
    <div class="field">
      <Comorbidity::EditionForm::Icd10Dropdown
        @form={{Form}}
        @changeset={{@changeset}}
        @patient={{@patient}}
      />
    </div>
  
    <div class="field">
      <Comorbidity::PathologyDetection
        @changeset={{@changeset}}
        @patient={{@patient}}
      />
    </div>
  
    {{#if (not-eq @patient.therapeuticalArea.id "mental")}}
      {{! @glint-expect-error }}
      <div>{{yield to="comorbidityAdditionalInfo"}}</div>
    {{/if}}
  
    <div class="field">
      <Form.Input @labelName="Allergies" @propertyName="medicatedAllergies" />
    </div>
  </Forc::Provider::Base>
  */
  {
    "id": "9/AUOAiN",
    "block": "[[[8,[39,0],null,[[\"@changeset\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@form\",\"@changeset\",\"@patient\"],[[30,2],[30,1],[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@changeset\",\"@patient\"],[[30,1],[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,3,[\"therapeuticalArea\",\"id\"]],\"mental\"],null],[[[1,\"    \"],[10,0],[12],[18,4,null],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    \"],[8,[30,2,[\"Input\"]],null,[[\"@labelName\",\"@propertyName\"],[\"Allergies\",\"medicatedAllergies\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]]]]]],[\"@changeset\",\"Form\",\"@patient\",\"&comorbidityAdditionalInfo\"],false,[\"forc/provider/base\",\"comorbidity/edition-form/icd10-dropdown\",\"comorbidity/pathology-detection\",\"if\",\"not-eq\",\"yield\"]]",
    "moduleName": "comorbidity/components/comorbidity/edition-form/index.hbs",
    "isStrictMode": false
  });
  class PathologyDetectionComponent extends _component2.default {
    get changeset() {
      var _this$args$patient$th;
      const changeset = this.args.changeset;
      if (!changeset.get("comorbidities") || ((_this$args$patient$th = this.args.patient.therapeuticalArea) === null || _this$args$patient$th === void 0 ? void 0 : _this$args$patient$th.id) !== "mental") {
        return changeset;
      }
      const comorbidities = [...changeset.get("comorbidities")];

      // Compatibility layer for legacy codes stored in DBs.
      // Those codes are of the form "ICD-F10-0"
      // The same icd10 compliant code would be "F10.0"
      const compliantComorbidities = comorbidities.map(code => new _comorbiditiesAndAllergiesChangedJournalEvent.SemeiaComorbidityCode(code).icd10Code);
      changeset.set("comorbidities", compliantComorbidities);
      return changeset;
    }
  }
  _exports.default = PathologyDetectionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PathologyDetectionComponent);
});