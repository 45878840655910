define("ember-app-scheduler/scheduler", ["exports", "@ember/destroyable", "@ember/object/events", "@ember/runloop", "@ember/test-waiters", "@glimmer/tracking", "rsvp"], function (_exports, _destroyable, _events, _runloop, _testWaiters, _tracking, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.beginTransition = beginTransition;
  _exports.default = void 0;
  _exports.endTransition = endTransition;
  _exports.reset = reset;
  _exports.routeSettled = routeSettled;
  _exports.setupRouter = setupRouter;
  _exports.whenRouteIdle = whenRouteIdle;
  var _class, _descriptor;
  /** @documenter yuidoc */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const APP_SCHEDULER_LABEL = 'ember-app-scheduler';
  const APP_SCHEDULER_HAS_SETUP = '__APP_SCHEDULER_HAS_SETUP__';
  let _whenRouteDidChange;
  let _whenRouteIdle;
  const IS_FASTBOOT = typeof window.FastBoot !== 'undefined';
  const waiter = (0, _testWaiters.buildWaiter)('ember-app-scheduler-waiter');
  reset();
  let Scheduler = (_class = class Scheduler {
    constructor() {
      _initializerDefineProperty(this, "isIdle", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  const scheduler = new Scheduler();
  var _default = _exports.default = scheduler;
  /**
   * Initializes the top level promise that initiates the post-render work.
   *
   * @public
   * @function beginTransition
   * @return {void}
   */
  function beginTransition() {
    if (_whenRouteDidChange.isResolved) {
      _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
      _whenRouteIdle = _whenRouteDidChange.promise.then(() => {
        let scheduledWorkToken = waiter.beginAsync();
        return new _rsvp.Promise(resolve => {
          (0, _runloop.schedule)('afterRender', null, () => {
            requestAnimationFrame(() => {
              requestAnimationFrame(resolve);
            });
          });
        }).finally(() => {
          waiter.endAsync(scheduledWorkToken);
          mark('appSchedulerEnd');
          measure('appScheduler', 'appSchedulerStart', 'appSchedulerEnd');
        });
      });
      scheduler.isIdle = false;
    }
  }

  /**
   * Initiates the post-render work.
   *
   * @public
   * @function endTransition
   * @return {void}
   */
  function endTransition() {
    _whenRouteDidChange.resolve();
    scheduler.isIdle = true;
    mark('appSchedulerStart');
  }

  /**
   * Connects the router's transition events to
   * app scheduler's work.
   *
   * @public
   * @function setupRouter
   * @param {RouterService|Router} router An instance of a RouterService or an Ember Router.
   * @return {void}
   */
  function setupRouter(router) {
    if (IS_FASTBOOT || router[APP_SCHEDULER_HAS_SETUP]) {
      return;
    }
    router[APP_SCHEDULER_HAS_SETUP] = true;

    // @ts-ignore
    (0, _events.addListener)(router, 'routeWillChange', beginTransition);
    // @ts-ignore
    (0, _events.addListener)(router, 'routeDidChange', endTransition);
    (0, _destroyable.registerDestructor)(router, reset);
  }

  /**
   * Resets the state of app scheduler's top-level scheduled work promise.
   *
   * @public
   * @function reset
   * @return {void}
   */
  function reset() {
    _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
    _whenRouteIdle = _whenRouteDidChange.promise.then();
    waiter.reset();
    if (!IS_FASTBOOT) {
      _whenRouteDidChange.resolve();
    }
  }

  /**
   * This promise, when resolved, approximates after content is painted.
   *
   * @public
   * @function whenRouteIdle
   * @return {Promise<any>} The scheduled work promise.
   */
  function whenRouteIdle() {
    return _whenRouteIdle;
  }

  /**
   * Allows for tests to pause until the scheduled work
   * promise is completed.
   *
   * @public
   * @function routeSettled
   * @return {Promise<any>} The scheduled work promise.
   */
  function routeSettled() {
    return _whenRouteIdle;
  }
  function _defer(label) {
    let _isResolved = false;
    let _resolve;
    let _reject;
    const promise = new _rsvp.Promise((resolve, reject) => {
      _resolve = () => {
        _isResolved = true;
        resolve();
      };
      _reject = reject;
    }, label);
    return {
      promise,
      resolve: _resolve,
      reject: _reject,
      get isResolved() {
        return _isResolved;
      }
    };
  }
  function mark(markName) {
    try {
      performance.mark(markName);
    } catch (ex) {
      console.warn("performance.mark could not be executed because of ".concat(ex.message));
    }
  }
  function measure(measureName, startMark, endMark) {
    try {
      performance.measure(measureName, startMark, endMark);
    } catch (ex) {
      console.warn("performance.measure could not be executed because of ".concat(ex.message));
    }
  }
});