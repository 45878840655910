/**
 * hl_getCpxCard must have been called.
 *
 * Optional parameters that can be set in 'optionalParameters' parameter:
 *   HTTP proxy:
 *     "s_proxyIpOrUrl"
 *     "i_proxyPort"
 *     "s_proxyLogin"
 *     "s_proxyPassword"
 *  Custom NTP server:
 *     "s_ntpIpOrUrl"
 *     "i_ntpPort"
 *  Connection Handle:
 *     "i_getConnectionHandle"      If set to 1, the webserver will return the handle of the connection used for DMP connection in addition to the unique instance ID
 *  Local patient Root OID
 *     "s_localPatientRootOid"
 *
 * Returns :
 * - the instance unique ID of the software. The number is unique per user account and do not change
 *  between restarts or software updates. It is used to identify the software instances that performed the DMP transactions.
 *
 * { [...]
 *    "s_instanceUniqueId":"2.25.186812548863990774547154938929646584355"
 * }
 * - if i_getConnectionHandle is set to 1, il also returns the connection handle used for Dmp transactions :
 * {
 *    [...]
 *    "i_connectionHandle" : 254678
 * }
 *
 *
 * @param {string}   pinCode                      E.g. "1234" for all test cards by default.
 * @param {string}   serverName                   Available values: "formation1", "formation2", "formation3", "formation5", "". See spec.
 *                                                Set it to an empty string to use the production DMP.
 * @param {number}   transactionsTimeoutInSeconds DMP transactions timeout.
 * @param {string}   practiceSetting              E.g. "AMBULATOIRE". See spec.
 * @param {number}   cpsPracticeLocationIndice    Practice location to use. Indice in the CPx card list of practices locations.
 * @param {function} resultCallback               The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_createDmpConnector = function( pinCode, serverName, transactionsTimeoutInSeconds, practiceSetting, cpsPracticeLocationIndice, optionalParameters, resultCallback )
{
    var serverUrl = "";

    // Empty string => production DMP.
    if( serverName === "" )
        serverUrl = "https://lps2.dmp.gouv.fr/si-dmp-server/v2/services";
    else
    {
        if( serverName.substr( 0 , 8 ) == "https://" )
        {
            serverUrl = serverName;
        }
        else
        {
            serverUrl = "https://lps." + serverName + ".dmp.gouv.fr/si-dmp-server/v2/services"
        }
    }

    var command = {
        "s_commandName"               : "hl_createDmpConnector",
        "s_dmpUrl"                    : serverUrl,
        "i_transactionsTimeout"       : transactionsTimeoutInSeconds,
        "s_practiceSetting"           : practiceSetting,
        "i_cpsPracticeLocationIndice" : cpsPracticeLocationIndice,
        "s_sessionId"                 : this.getSessionId()
    };

    if( pinCode.length == 4 )
    {
        command.s_pinCode = pinCode;
    }



    // Merge the HTTP proxy parameters and/or the NTP proxy parameters set in optionalParameters.
    if( optionalParameters )
    {
        for( var p in optionalParameters )
        {
            command[ p ] = optionalParameters[ p ];
        }
    }

    return this.sendCommand( command, 60, resultCallback, false, (function(self){
        return function(result) {
            if (result.s_status == 'OK') {
                self.setState('DmpConnector', true);
                self._refreshCallback();
            }
        }
  })(this));
};




/**
 * @brief Get Certified identity (TD00) for a given patient.
 * @param {string}  nirOD       Nir "Ouvrant-Droit"     (as found on Vitale Card)
 * @param {string}  birthDate   Patient birth date (in format YYYYMMDD)
 * @param {int}     birthRank   Patient birth rank (almost always 1, except for twins)
 * @param {string}  nirIndividu Patient NIR Individu (only if known)
 *
 *
 * Exemple of output:
  {
  "Ins":
    {
      "s_ins": "188102B17295264",
      "s_insType": "T"
    },
  "s_birthday": "19881012",
  "s_patientGivenName": "FIGATELLIX",
  "s_patientName": "",
  "s_status": "OK"
  }
 */
DMPConnect.prototype.hl_getCertifiedIdentity = function( nirOD, birthDate, birthRank, nirIndividu, resultCallback )
{
    var command =
    {
        "s_commandName" : "hl_getCertifiedIdentity",
        "s_sessionId"   : this.getSessionId(),
        "s_nirOD"       : nirOD ,
        "s_birthDate"   : birthDate,
        "i_birthRank"   : birthRank,
        "s_nirIndividu" : nirIndividu
    };

    return this.sendCommand( command , 20 , resultCallback,false, (function(self){
        return function(result) {
            if (result.s_status == 'OK') {
                self.setDmpState('certifiedIdentity', true);
                self._refreshCallback();
            }
        }
    })(this));
}

/**
  * Try to get INS-NIR from INS-C
  */
DMPConnect.prototype.hl_getInsNirFromInsC = function( insC, resultCallback )
{
    var command =
    {
        "s_commandName" : "hl_getInsNirFromInsC",
        "s_sessionId"   : this.getSessionId(),
        "s_insC"        : insC
    };

    return this.sendCommand( command, 30, resultCallback );
}


/**
 * @brief update the practice location (and setting) for the current dmp Connector
 * @param {int} practiceLocationIndice          Practice location to use. Indice in the CPx card list of practices locations.
 * @param {string}   practiceSetting            E.g. "AMBULATOIRE". See spec.
 */
DMPConnect.prototype.hl_setPracticeLocation = function( practiceSetting , practiceLocationIndice , resultCallback )
{
    var command =
    {
        "s_commandName"               : "hl_setPracticeLocation",
        "i_cpsPracticeLocationIndice" : practiceLocationIndice,
        "s_practiceSetting"           : practiceSetting,
        "s_sessionId"                 : this.getSessionId()
    };

    return this.sendCommand( command , 20 , resultCallback );
}

DMPConnect.DMPStatus =
{
    DMPExist    : 1,
    DMPIsClosed : 2,
    DMPNotFound : 3,
    DMPError    : 4
};

DMPConnect.Sex =
{
    UnknownSex : 1,
    Male       : 2,
    Female     : 3
};

DMPConnect.Gender =
{
    UnknownGender : 1,
    M             : 2,
    Mme           : 3
};

DMPConnect.UserAuthorizationStatus =
{
    AuthorizationError   : 1, /// Means that the DMP does not exists or was closed.
    AuthorizationExist   : 2,
    AuthorizationExpired : 3,
    AuthorizationDenied  : 4,
    NoAuthorization      : 5
};

/**
 * TD 0.2: Check DMP existence and the status of the authorizations of the PS on it.
 * See specs.
 *
 * hl_createDmpConnector must have been called.
 *
 * Example of output:
 * {
 * "ExistingTestAnswer": {
 *   "AdminData": {
 *     "Ins": {
 *       "s_ins": "255069999999934",
 *       "s_insType": "D"
 *     },
 *     "s_birthday": "19550615",
 *     "s_insC": "0448685716413283718907",              // Note return is optional: it only exists if patient has an INS-C
 *     "s_patientGivenName": "Nathalie",
 *     "s_patientName": "DESMAUX"
 *   },
 *   "i_dmpStatus": 1,
 *   "i_sex": 3,
 *   "i_userAuthorization": 2,
 *   "i_webAccessIsOpen": 1,
 *   "s_closingDate": "",
 *   "s_closingMessage": ""
 * },
 * "i_treatingPhysician": 0,
 * "s_status": "OK"
 *}
 *
 * @param {string}   ins              The INS to check.
 * @param {function} resultCallback   The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getDirectAuthenticationDMPStatus = function(ins, resultCallback)
{
    var command = {
        "s_commandName" : "hl_getDirectAuthenticationDMPStatus",
        // INS can be given using the s_ins field :
        "s_ins"        : ins,
        // Or using the Ins structure :
        /*
        "Ins" :
        {
            "s_ins" : ins ,
            "s_insType" : "N"
        },
        */
        "s_sessionId"   : this.getSessionId()
    };

    return this.sendCommand(command, 6, resultCallback,false, (function(self){
        return function(result) {
            if (result.s_status == 'OK') {
                self.setDmpState('directAuthenticationStatus', true);
                self._refreshCallback();
            }
        }
    })(this));
};



/**
  * @brief Document status.
  */
DMPConnect.DocumentStatus =
{
    Approved   : 1,
    Deprecated : 2,
    Archived   : 4
};

/**
  * @brief Document confidentiality visibility types.
  */
DMPConnect.DocumentVisibility =
{
    Normal                                     : 1,
    PatientHidden                              : 2,
    HealthcareProfesionnalHidden               : 4,
    GuardianHidden                             : 8,
    PatientHiddenGuardianHidden                : 10,
    GuardianHiddenHealthcareProfesionnalHidden : 12
};

/**
  * @brief Document formats.
  */
DMPConnect.DocumentFormat =
{
    // Unstructured documents.
    PlainText        : 1,
    RtfText          : 2,
    JpgImage         : 3,
    TiffImage        : 4,
    PdfApplication   : 5,

    // Structured documents.
    VsmDocument      : 6,
    CrBiologie       : 7,

    // DLU
    DluDocument      : 40,      // 'DLU - Admission en EHPAD
    DluFludtDocument : 41,      // 'DLU - Fiche de liaison d'urgence - transfert de l'EHPAD vers les urgences'
    DluFludrDocument : 42,      // 'DLU - Fiche de liaison d'urgence - retour des urgences vers l'EHPAD'

    // IDL
    LdlEesDocument   : 44,      // 'Information de liaison - Lettre de Liaison à l'entrée d'un établissement de santé'
    LdlSesDocument   : 45,      // 'Information de liaison - Lettre de Liaison à la sortie de l'établissement de santé'

    // HR
    HrDocument       : 59,      // 'Données de remboursement'

    // HVAC
    HvacDocument     : 60,      // 'Historique de vaccination'
    HvacNoteDocument : 61       // 'Note de vaccination'
} ;

/**
  * @brief Performer roles.
  */
DMPConnect.PerformerRole =
{
    Actor     : 1,
    MainActor : 2,
    Assistant : 3
};

/**
  * @brief Event code classification.
  */
DMPConnect.EventCodeType =
{
    CCAM          : 1, ///< Actes médicaux, y compris imagerie, anatomocytopathologie, …
    SNOMED_3_5_VF : 2, ///< Actes médicaux, y compris imagerie, anatomocytopathologie, …
    CIM_10        : 3, ///< Diagnostics
    LOINC         : 4, ///< Comptes rendus d'examens biologiques
    DRC           : 5  ///< Résultats de consultation de médecine générale.
};

/**
 * TD 3.1: Search for documents on the DMP.
 * See specs.
 *
 * hl_createDmpConnector must have been called.
 *
 * Any subsequent call will discard the document handles (i_handle).
 *
 * IN : All parameters are optional except "s_ins".
 *   "s_ins"
 *   "Categories"
 *   {
 *     "#0" : "46241-6",
 *     "#1" : "11488-4"
 *     ...
 *   }
 *   "Formats"
 *   {
 *     "#0", "urn:ihe:pat:apsr:larynx:2010"
 *   }
 *   "Practices"
 *   {
 *     "#0", "PALLIATIF"
 *   }
 *   "s_serviceStartDateTop"
 *   "s_serviceStartDateBottom"
 *   "s_serviceStopDateTop"
 *   "s_serviceStopDateBottom"
 *   "s_creationDateTop"
 *   "s_creationDateBottom"
 *   "s_submissionDateTop"
 *   "s_submissionDateBottom"
 *   "i_status"     : See DMPConnect.DocumentStatus. Multiple choices are possible by adding values of this enum.
 *   "i_visibility" : See DMPConnect.DocumentVisibility. Multiple choices are possible by adding values of this enum.
 *   "i_disableMetadataSearch"
 * OUT
 *
 * {
 *  "Documents": [
 *    {
 *      "Authors": [
 *        {
 *          "i_handle": 524290,
 *          "i_hpAuthenticationMode": 25,
 *          "s_hpGiven": "ALAIN",
 *          "s_hpInternalId": "899900023351",
 *          "s_hpName": "GENE RPPS",
 *          "s_hpProfession": "10",
 *          "s_hpProfessionOid": "1.2.250.1.71.1.2.7",
 *          "s_hpProfessionDescription": "Médecin",
 *          "s_hpSpeciality": "SM26",
 *          "s_hpSpecialityDescription": "Qualifié en médecine générale (SM)"
 *        }
 *      ],
 *      "EventCodes": [],
 *      "i_document_Format": 5,
 *      "i_document_Status": 1,
 *      "i_document_Visibility": 4,
 *      "i_handle": 131073,
 *      "s_classCode": "10",
 *      "s_creationDate": "20161110165758",
 *      "s_description": "Description très utile",
 *      "s_healthCareFacilityTypeCode": "SA07",
 *      "s_nextUUId": "",                           (*) Only available if metadata search is performed (default)
 *      "s_practiceSettingCode": "AMBULATOIRE",
 *      "s_previousUUId": "",                       (*) Only available if metadata search is performed (default)
 *      "s_serviceStartDate": "20161110165758",
 *      "s_serviceStopDate": "",
 *      "s_submissionDate": "",                     (*) Only available if metadata search is performed (default)
 *      "s_submissionSetId": "",                    (*) Only available if metadata search is performed (default)
 *      "s_title": "Document de test DmpConnect JS",
 *      "s_typeCode": "46241-6",
 *      "s_uniqueId": "2.25.12275515752198996114817914428683693778",
 *      "s_uuid": "urn:uuid:00f8e428-a767-11e6-95bf-00163e58645d"
 *    },
 *    {
 *      "Authors": [*],
 *      "EventCodes": [*],
 *       *
 *    },
 *  ],
 *  "s_status": "OK"
 *}
 * @param {string}   ins              The INS of the DMP to look in.
 * @param {object}   command          Allow to extend the command for optionals parameters
 * @param {function} resultCallback   The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_findDocuments = function(ins, command, resultCallback)
{
    command = command || {};
    command.s_commandName = "hl_findDocuments";
    // INS number can be given the s_ins field :
    command.s_ins        = ins;
    // or using the Ins structure :
    // command.Ins =
    // {
    //     "s_ins" : ins ,
    //     "s_insType" : "N"
    // };
    command.s_sessionId   = this.getSessionId();

    return this.sendCommand(command, 600, resultCallback);
};

/**
  * @brief Get all documents associated with a submission Set.
  *
  * IN
  * {
  *     s_ins
  *     s_submissionSetId
  * }
  */
DMPConnect.prototype.hl_getSubmissionSetDocuments = function( ins, submissionSetId, command, resultCallback )
{
    command = command || {};
    command.s_commandName = "hl_getSubmissionSetDocuments";
    // INS number can be given the s_ins field :
    command.s_ins        = ins;
    // or using the Ins structure :
    // command.Ins =
    // {
    //     "s_ins" : ins ,
    //     "s_insType" : "N"
    // };
    command.s_sessionId      = this.getSessionId();
    command.s_submissionSetId = submissionSetId;

    return this.sendCommand( command, 600, resultCallback );
}

/**
  * @brief Get Submission set ID of a given document.
  *
  * IN
  * {
  *    s_ins                PatientIns
  *    s_documentUniqueId   Document UniqueId
  *    s_documentUuid       Document UUID (optional)
  * }
  * OUT
  * {
  *    s_submissionSetId    ID of the submission set which contains the given document.
  * }
  */
DMPConnect.prototype.hl_getDocumentSubmissionSetIdByUniqueId = function( ins, uniqueId, uuid, command , resultCallback )
{
    command = command || {};
    command.s_commandName      = "hl_getDocumentSubmissionSetIdByUniqueId";
    command.s_sessionId        = this.getSessionId();

    command.s_ins              = ins ;
    command.s_documentUniqueId = uniqueId;
    command.s_documentUuid     = uuid;


    return this.sendCommand( command, 60, resultCallback );
}


/**
  * @brief For TD 0.3 below.
  */
DMPConnect.UserAuthorizationAction =
{
    AddAuthorization    : 1,
    RemoveAuthorization : 2
};

/**
 * @brief TD 0.3 Update HP authorization on a DMP.
 * See specs.
 * hl_createDmpConnector must have been called.
 *
 * IN
 *   "s_ins"
 *   "i_action" : 1 = AddAuthorization, 2 = RemoveAuthorization. See UserAuthorizationAction enum.
 *   "i_setTreatingPhysician" : To remove the treating physician status, call the function with AddAuthorization and false.
 * OUT
 *
 * @param {string}   ins               The INS of the DMP we need to access or not.
 * @param {number}   action            The action to perform (i.e. add or remove the authorization). See UserAuthorizationAction.
 * @param {number}   treatingPhysician 1: enable "Medecin Traitant" access to the given DMP. Ignored if action == RemoveAuthorization
 * @param {object}   command           Allow to extend the command for optionals parameters
 * @param {function} resultCallback    The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_updateUserDmpAccessAuthorization = function(ins, action, treatingPhysician, command, resultCallback)
{
    command = command || {};
    command.s_commandName          = "hl_updateUserDmpAccessAuthorization";
    command.s_ins                  = ins;
    command.i_setTreatingPhysician = treatingPhysician;
    command.i_action               = action;
    command.s_sessionId            = this.getSessionId();

    return this.sendCommand(command, 10, resultCallback);
};

/**
 * @brief TD 0.4 Get accessible DMPs list for the current user (CPx mode) or structure (ES mode).
 * See specs.
 * hl_createDmpConnector must have been called.
 *
 * IN
 *   "s_type" : "LASTAUTORIZATION" or "LASTDOC"
 *   "s_date" : Format: YYYYMMDD
 * OUT
 * {
 *   "AccessibleDmps": [
 *     {
 *       "s_birthday": "19980121",
 *       "s_ins": "",
 *       "s_lastAccessDate": "20161121112348",
 *       "s_lastAddDate": "",
 *       "s_lastUpdateDate": "20161121111802",
 *       "s_patientBirthName": "",
 *       "s_patientGivenName": "THIBAULT",
 *       "s_patientName": "AATITROIS",
 *       "s_insC" : "0529528580088131833916",
 *       "i_treatingPhysician" : 1,
 *     },
 *     { ...
 *     },
 *     { ...
 *     }
 *   ],
 *   "s_status": "OK"
 * }
 *
 * @param {string}   type             "LASTAUTORIZATION" or "LASTDOC".
 * @param {string}   date             Format: YYYYMMDD.
 * @param {object}   command          Allow to extend the command for optionals parameters
 * @param {function} resultCallback   The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getAccessibleDMPList = function(type, date, command, resultCallback)
{
    command = command || {};
    command.s_commandName = "hl_getAccessibleDMPList";
    command.s_type        = type;
    command.s_date        = date;
    command.s_sessionId   = this.getSessionId();

    return this.sendCommand(command, 30, resultCallback);
};

/**
 * @brief TD 3.2 Download a document from the DMP.
 * See specs.
 * hl_createDmpConnector must have been called.
 *
 * The document is stored in the system temporary directory.
 * Its URL is returned in 's_filePath'.
 *
 * IN
 *       "i_documentHandle"
 *   (*) "i_getCdaHeaders"     (Optional) Set it to 1 to retrieve CDA headers in HTML format
 *   (*) "i_getCdaContent"     (Optional) Set it to 1 to retrieve CDA content in base64 format.
 *   (*) "i_getPerformer"      (Optional) Set it to 1 to retreive informations relative to the Performer (Hp data, and it's role).
 *   (*) "i_getInformants"     (Optional) Set it to 1 to retreive informants of associated to the document if any.
 *   (*) "i_getAuthors"        (Optional) Set it to 1 to retreive authors of the document.
 *   (*) "i_getDataEnterer"    (Optional) Set it to 1 to retreive data enterer of the document.
 *   (*) "i_getParticipants"   (Optional) Set it to 1 to retreive Participants.
 *   (*) "i_getAuthenticators" (Optional) Set it to 1 to retreive authenticators.
 * OUT
 *   "s_filePath": Complete local file URL (client side).
 *                 Ex.: file:///C:/Users/cyrion/AppData/Local/Temp/5fd4d6d3-ed32-11e5-bb05-00163e58645d.txt
 *                 The name of the file is based on 'the uuid of the document'.
 *   "Performer"           :  Information relative to the Performer. (only present if i_getPerformer is set to 1).
 *                            The structure will contains the following data:
 *
 *   "Performer":
 *   {
 *     "Hp":
 *     {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if Performer has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if Performer has at least one telecom.
 *      },
 *      "i_role": 2                         Type : DMPConnect.PerformerRole
 *    }
 *    "s_cdaHeadersInBase64":        CDA headers in HTML format. (only present if i_getCdaHeaders is set to 1).
 *    "s_cdaContentInBase64":        CDA document in Base64 format (only present if i_getCdaContent is set to 1).
 *    "i_documentFormat"             (enum DocumentFormat)
 *    "s_documentFormatCode"         (ex: urn:ihe:iti:xds-sd:pdf:2008)
 *    "s_documentFormatDescription"  (ex: Document à corps non structuré en Pdf/A-1) - Only for display purpose.
 *    "Informants" : []               Array of informants. (only present if i_getInformants is set to 1) each informant is structured as:
 *    {
 *      "i_type"         :              // Type: DmpConnect.InformantType
 *      "i_relationType" :              // Type: DmpConnect.InformantRelationType
 *      "s_name"         : ""
 *      "s_given"        : ""
 *      "Addresses"      : [ {addr1}, {addr2} ]
 *      "Telecoms"       : [ {tel1}, {tel2} ]
 *    },
 *    "Authors" : []                  Array of authors. (only present if i_getAuthors is set to 1) each HP is defined as follow:
 *    {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if author has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if author has at least one telecom.
 *     },
 *     DataEnterer                      HP structure. (Only present if i_getDataEnterer is set to 1).
 *     {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if data enterer has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if data enterer has at least one telecom.
 *     },
 *     Participants : []            Array of Participants. (only present if i_getParticipants is set to 1). Each Participant is defined as follow:
 *     {
 *      s_type
 *      s_role
 *      s_class
 *      s_participationDateStart
 *      s_participationDateEnd
 *      Person              // Same as HP structure.
 *      {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if participant has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if participant has at least one telecom.
 *      }
 *     }
 *     Authenticators : []          Array of authenticators. (Only present if i_getAuthenticators is set to 1). Each authenticator is defined as follow:
 *     {
 *          s_authenticationDate    :
 *          Hp :            // Same as HP structure.
 *          {
 *              "i_hpAuthenticationMode":
 *              "i_hpInternalIdType":
 *              "s_hpGiven":
 *              "s_hpInstitution":
 *              "s_hpInternalId":
 *              "s_hpName":
 *              "s_hpProfession":
 *              "s_hpProfessionDescription":
 *              "s_hpProfessionOid":
 *              "s_hpSpeciality":
 *              "s_hpSpecialityDescription":
 *              "Addresses"      : [ {addr1}, {addr2} ]          - Only present if participant has at least one address.
 *              "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if participant has at least one telecom.
 *          }
 *     }
 *
 *
 *    Address structure is:
 *    {
 *      "i_type"                : ""    // Type: DmpConnect.AddressType
 *      "s_country"             : ""
 *      "s_state"               : ""
 *      "s_city"                : ""
 *      "s_postalCode"          : ""
 *      "s_houseNumber"         : ""
 *      "s_houseNumberNumeric"  : ""
 *      "s_streetName"          : ""
 *      "i_streetNameType"      : ""    // Type: DmpConnect.StreetNameType
 *      "s_additionalLocator"   : ""
 *      "s_unitId"              : ""
 *      "s_postBox"             : ""
 *      "s_precInct"            : ""
 *    }
 *    Telecom structure is:
 *    {
 *      "i_type"                : ""    // Type: TelecomType    - Note: it can be a bitwise combination of TelecomType.
 *      "i_usage"               : ""    // Type: TelecomUsage
 *      "s_value"               : ""
 *    }
 *
 * @param {number}   hdoc             Handle of the document to download. Handles are returned by hl_findDocuments (in i_handle).
 * @param {object}   command          Allow to extend the command for optionals parameters
 * @param {function} resultCallback   The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getDocumentContent = function(hdoc, command, resultCallback)
{
    command = command || {};
    command.s_commandName    = "hl_getDocumentContent";
    command.s_sessionId      = this.getSessionId();

    command.i_documentHandle    = hdoc;
    command.i_getCdaHeaders     = 1;
    command.i_getCdaContent     = 1;
    command.i_getPerformer      = 1;
    command.i_getInformants     = 1;
    command.i_getAuthors        = 1;
    command.i_getDataEnterer    = 1;
    command.i_getParticipants   = 1;
    command.i_getAuthenticators = 1;

    return this.sendCommand(command, 90, resultCallback);
};

/**
 * @brief TD 3.2 Download a document from the DMP.
 * See specs.
 * hl_createDmpConnector must have been called.
 *
 * IN
 *      s_ins                        Patient INS.
 *      s_documentUniqueId           Document Unique ID
 *  (*) s_documentUuid               Document UUID. If not provided, an additional transaction is performed.
 *  (*) i_getCdaHeaders              (Optional) Set it to 1 to retrieve CDA headers in HTML format
 *  (*) i_getCdaContent              (Optional) Set it to 1 to retrieve CDA content in base64 format.
 *  (*) i_getPerformer               (Optional) Set it to 1 to retreive informations relative to the Performer (Hp data, and it's role). * OUT
 *  (*) i_getInformants              (Optional) Set it to 1 to retreive informants of associated to the document if any.
 *  (*) i_getAuthors                 (Optional) Set it to 1 to retreive authors of the document.
 *  (*) i_getDataEnterer             (Optional) Set it to 1 to retreive data enterer of the document.
 *  (*) i_getParticipants            (Optional) Set it to 1 to retreive Participants.
 *  (*) i_getAuthenticators          (Optional) Set it to 1 to retreive authenticators.
 * OUT
 *      s_fileContentInBase64        Content of the document in Base64 format
 *      s_cdaHeadersInBase64         CDA headers in HTML format. (only present if i_getCdaHeaders is set top 1).
 *      s_cdaContentInBase64         CDA document in Base64 format (only present if i_getCdaContent is set to 1).
 *      Performer                    Information relative to the Performer. (only present if i_getPerformer is set to 1).
 *      Informants                   Array of informants. (only present if i_getInformants is set to 1)
 *      i_documentFormat             (Values from DMPConnect.DocumentFormat)
 *      s_documentFormatCode         (ex: urn:ihe:iti:xds-sd:pdf:2008)
 *      s_documentFormatDescription  (ex: Document à corps non structuré en Pdf/A-1) - Only for display purpose.
 *
 *  "Performer":
 *   {
 *     "Hp":
 *     {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"                : [ {addr1}, {addr2} ]          - Only present if Performer has at least one address.
 *         "Telecoms"                 : [ {tel1}, {tel2} ]            - Only present if Performer has at least one telecom.
 *      },
 *      "i_role": 2                         Type : DMPConnect.PerformerRole
 *    }
 *    "Informants" : []                 Array of informants. (only present if i_getInformants is set to 1) each informant is structured as:
 *    {
 *      "i_type"         :              // Type: DmpConnect.InformantType
 *      "i_relationType" :              // Type: DmpConnect.InformantRelationType
 *      "s_name"         : ""
 *      "s_given"        : ""
 *      "Addresses"      : [ {addr1}, {addr2} ]
 *      "Telecoms"       : [ {tel1}, {tel2} ]
 *    }
 *    "Authors" : []                    Array of authors. (only present if i_getAuthors is set to 1) each HP is defined as follow:
 *    {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if author has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if author has at least one telecom.
 *     }
 *     DataEnterer                      HP structure. (Only present if i_getDataEnterer is set to 1).
 *     {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if data enterer has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if data enterer has at least one telecom.
 *     },
 *     Participants : []            Array of Participants. (only present if i_getParticipants is set to 1). Each Participant is defined as follow:
 *     {
 *      s_type
 *      s_role
 *      s_class
 *      s_participationDateStart
 *      s_participationDateEnd
 *      Person // A HP structure.
 *      {
 *         "i_hpAuthenticationMode":
 *         "i_hpInternalIdType":
 *         "s_hpGiven":
 *         "s_hpInstitution":
 *         "s_hpInternalId":
 *         "s_hpName":
 *         "s_hpProfession":
 *         "s_hpProfessionDescription":
 *         "s_hpProfessionOid":
 *         "s_hpSpeciality":
 *         "s_hpSpecialityDescription":
 *         "Addresses"      : [ {addr1}, {addr2} ]          - Only present if participant has at least one address.
 *         "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if participant has at least one telecom.
 *      }
 *     }
 *     Authenticators : []          Array of authenticators. (Only present if i_getAuthenticators is set to 1). Each authenticator is defined as follow:
 *     {
 *          s_authenticationDate    :
 *          Hp :            // Same as HP structure.
 *          {
 *              "i_hpAuthenticationMode":
 *              "i_hpInternalIdType":
 *              "s_hpGiven":
 *              "s_hpInstitution":
 *              "s_hpInternalId":
 *              "s_hpName":
 *              "s_hpProfession":
 *              "s_hpProfessionDescription":
 *              "s_hpProfessionOid":
 *              "s_hpSpeciality":
 *              "s_hpSpecialityDescription":
 *              "Addresses"      : [ {addr1}, {addr2} ]          - Only present if participant has at least one address.
 *              "Telecoms"       : [ {tel1}, {tel2} ]            - Only present if participant has at least one telecom.
 *          }
 *     }
 *
 *    Address structure is:
 *    {
 *      "i_type"                : ""    // Type: DmpConnect.AddressType
 *      "s_country"             : ""
 *      "s_state"               : ""
 *      "s_city"                : ""
 *      "s_postalCode"          : ""
 *      "s_houseNumber"         : ""
 *      "s_houseNumberNumeric"  : ""
 *      "s_streetName"          : ""
 *      "i_streetNameType"      : ""    // Type: DmpConnect.StreetNameType
 *      "s_additionalLocator"   : ""
 *      "s_unitId"              : ""
 *      "s_postBox"             : ""
 *      "s_precInct"            : ""
 *    }
 *    Telecom structure is:
 *    {
 *      "i_type"                : ""    // Type: TelecomType
 *      "i_usage"               : ""    // Type: TelecomUsage
 *      "s_value"               : ""
 *    }
 * */
DMPConnect.prototype.hl_getDocumentContentByUniqueId = function (ins, uniqueId, uuid, command, resultCallback)
{
    command = command || {};
    command.s_commandName = "hl_getDocumentContentByUniqueId";
    command.s_sessionId   = this.getSessionId();

    command.s_ins               = ins;
    command.s_documentUniqueId  = uniqueId;
    command.s_documentUuid      = uuid;
    command.i_getCdaHeaders     = 1;
    command.i_getCdaContent     = 1;
    command.i_getPerformer      = 1;
    command.i_getInformants     = 1;
    command.i_getAuthors        = 1;
    command.i_getDataEnterer    = 1;
    command.i_getParticipants   = 1;
    command.i_getAuthenticators = 1;

    return this.sendCommand(command, 90, resultCallback);
}




/**
 * @brief Toggle the Document visibility status between "Hidden to HP that are not treating physician"
 *  and "Visible to all HP" (TD 3.3). See spec.
 * IN
 *     "s_healthCareSettings"
 *     "i_document"
 *     "i_newVisibility"
 * OUT
 */
DMPConnect.prototype.hl_updateDocumentVisibility = function(hdoc, healthcareSettings, newVisibility, command, resultCallback)
{
    command = command || {};
    command.s_commandName        = "hl_updateDocumentVisibility";
    command.i_document           = hdoc;
    command.s_healthCareSettings = healthcareSettings;
    command.i_newVisibility      = newVisibility;
    command.s_sessionId          = this.getSessionId();

    return this.sendCommand(command, 5, resultCallback);
};

/**
 * @brief Toggle the Document visibility status between "Hidden to HP that are not treating physician"
 *  and "Visible to all HP" (TD 3.3). See spec.
 *
 * IN
 *         "s_ins"                 Patient INS.
 *         "s_documentUniqueId"    Document Unique Id.
 *   (*)   "s_documentUuid"        Document UUID. Optional only if current document status is 'Approved'. If not provided, an additional transaction is performed. Thus slowing down the command.
 *         "s_healthCareSettings"  Healthcare settings.
 *         "i_newVisibility"       New visibility.
 * OUT
 */
DMPConnect.prototype.hl_updateDocumentVisibilityByUniqueId = function( ins , documentUniqueId, documentUuid, healthcareSettings, newVisibility, command, resultCallback )
{
    command               = command || {};
    command.s_commandName = "hl_updateDocumentVisibilityByUniqueId";
    command.s_sessionId   = this.getSessionId();

    command.s_ins                = ins;
    command.s_documentUniqueId   = documentUniqueId;
    command.s_documentUuid       = documentUuid;
    command.s_healthCareSettings = healthcareSettings;
    command.i_newVisibility      = newVisibility;

    return this.sendCommand(command, 10, resultCallback);
}

/**
 * @brief Toggle status between 'Approved' and 'Archived' (TD 3.3). See spec.
 * IN
 *     "s_healthCareSettings"
 *     "i_document"
 * OUT
 */
DMPConnect.prototype.hl_updateDocumentStatus = function(hdoc, healthcareSettings, command, resultCallback)
{
    command = command || {};
    command.s_commandName        = "hl_updateDocumentStatus";
    command.i_document           = hdoc;
    command.s_healthCareSettings = healthcareSettings;
    command.s_sessionId          = this.getSessionId();

    return this.sendCommand(command, 5, resultCallback);
};

/**
 * @brief Toggle status between 'Approved' and 'Archived' (TD 3.3). See spec.
 * IN
 *         "s_ins"                 Patient INS.
 *         "s_documentUniqueId"    Document Unique Id.
 *   (*)   "s_documentUuid"        Document UUID. Optional only if current document status is 'Approved'. If not provided, an additional transaction is performed. Thus slowing down the command.
 *         "s_healthCareSettings"  Healthcare settings.
 * OUT
 */
DMPConnect.prototype.hl_updateDocumentStatusByUniqueId = function( ins, documentUniqueId, documentUuid, healthcareSettings, command, resultCallback )
{
    command               = command || {};
    command.s_commandName = "hl_updateDocumentStatusByUniqueId";
    command.s_sessionId   = this.getSessionId();

    command.s_ins                = ins;
    command.s_documentUniqueId   = documentUniqueId;
    command.s_documentUuid       = documentUuid;
    command.s_healthCareSettings = healthcareSettings;

    return this.sendCommand(command, 10, resultCallback);
}


/*
 * @brief Delete a document from the DMP. (TD 3.3). See spec.
 * IN
 *     "s_uniqueId"
 *     "s_ins"
 *     "s_healthCareSettings"
 *     "s_uuid"
 * OUT
 *
 * @note This function only delete an active document, to delete an archived document, use hl_deleteArchivedDocument
 */
DMPConnect.prototype.hl_deleteDocument = function(uid, ins, healthcareSettings, command, resultCallback)
{
    command = command || {};
    command.s_commandName        = "hl_deleteDocument";
    command.s_uniqueId           = uid;
    // INS number can be given s_ins field:
    command.s_ins               = ins;
    // or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins,
    //     "s_insType" : "N"
    // };
    command.s_healthCareSettings = healthcareSettings;
    command.s_sessionId          = this.getSessionId();

    return this.sendCommand(command, 5, resultCallback);
};

/**
  * Delete an archived document from the DMP (TD3.3c). See spec.
  * IN
  *     "s_uniqueId"
  *     "s_ins"
  *     "s_healthCareSettings"
  *     "s_uuid"
  * OUT
  *
  * @note This function only delete an archived document, to delete an active document, use hl_deleteDocument
  */
DMPConnect.prototype.hl_deleteArchivedDocument = function(uid, ins, healthcareSettings, command, resultCallback)
{
    command = command || {};
    command.s_commandName        = "hl_deleteArchivedDocument";
    command.s_uniqueId           = uid;
    // INS number can be given s_ins field:
    command.s_ins                = ins;
    // or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins,
    //     "s_insType" : "N"
    // };
    command.s_healthCareSettings = healthcareSettings;
    command.s_sessionId          = this.getSessionId();

    return this.sendCommand(command, 5, resultCallback);
};

/**
  * @brief Document visibility types.
  */
DMPConnect.DocumentVisibility =
{
    Normal                          : 1,
    PatientHidden                   : 2,
    HealthcareProfesionnalHidden    : 4,
    GuardianHidden                  : 8,
};


/**
 * @brief TD 2.1 Send a document to the DMP.
 *
 * hl_createDmpConnector must have been called.
 *
 * The document must be stored in a local file.
 *
 * IN
 *   "s_fileContentBase64",        // Document content in base64.
 *   "s_ins",
 *   "s_documentTitle",
 *   "s_documentDescription",
 *   "s_documentCategory",         // See specs section 9.1.5 "TABLE 5 CATEGORIES DE DOCUMENT (TYPECODE)".
 *   "i_documentVisibility",       // Normal=1. See enum DocumentVisibility.
 *   "i_documentFormat",           // (*) PlainText = 1, PdfApplication = 5, etc. Cf. enum DocumentFormat.
 *   "s_documentFormatCode"        // (*) PlainText = urn:ihe:iti:xds-sd:text:2008, etc.
 *   "s_documentVersionNumber"     // Optional.
 *   "s_documentSetIdRoot"         // Optional.
 *   "s_documentSetIdExtension"    // Optional.
 *   "s_healthcareSetting",        // See specs section 9.1.8 "TABLE 8 - CADRE DE SOINS (HEALTHCARE SETTINGS)".
 *   "s_replacedDocumentUniqueId", // Optional.
 *   "s_submissionSetTitle",       // Optional.
 *   "s_submissionSetDescription"  // Optional.
 *   "s_submissionSetOid"          // Optional.
 *   "s_localPatientId"            // Optional.
 *   "s_eventCodes"                // Optional.
 *   "i_transcodeTypeCode"         // Optional. If true (1), transform the value of "s_documentCategory" to make sure the most up to date value is sent to the DMP servers. If false (0), an obsolete value of "s_documentCategory" will raise an error. Default Off (0)
 *   "i_forceSchematronsValidation"// Optional. If true (1), enable schematrons validation for unstructured documents (default: off).
 *   "i_retrieveDocumentUuid"      // Optional. If true (1), a supplemental transaction is performed to get technical identifier (UUID) of the document sent. (Default: false).
 *   "i_disablePdfa1Conversion"    // Optional. If true (1), PDF/A1 conversion is disabled.
 *   "i_pdfa1IgnoreTransparency"   // Optional. If true (1), Transparency is ignored during PDF/A1 conversion.
 *   "i_pdfa1ImageResolution"      // Optional. Set maximum resolution (in DPI) for images during PDF/A1 conversion. If not set default resolution is employed (200 DPI).
 *   "s_creationDate"              // Optional. Document original creation date. Format: YYYYMMDDhhmmss[+-]hhmm : Local time relative to UTC time (with time difference) + seconds.
 *   "s_serviceStartDate"          // Optional. Start date of the medical act described by the document
 *   "s_serviceStopDate"           // Optional. Ending date of the medical act described by the document.
 *   "s_documentOid"               // Optional. Document OID, only set the value if the structure has it's own OIDs.
 *   "s_styleSheetInBase64"        // Optional. Document stylesheet (xsl).
 *   Informants                    // Optional, array of Informant.
 *   TreatingPhysician             // Optional, Hp Structure.
 *   AdditionalAuthors             // Optional, Array of HP structure.
 *   Performer                     // Optional, Performer structure { Hp : {}, i_role }
 *
 *  Formats for s_serviceStartDate et s_serviceStopDate:
 *     YYYYMMJJ               : Local time
 *     YYYYMMDDhhmm[+-]hhmm   : Local time relative to UTC time (with time difference).
 *     YYYYMMDDhhmmss[+-]hhmm : idem + seconds.
 *
 * (*) User must define the format of the document using either:
 * - i_documentFormat         In this case format is defined in enum DMPConnect.DocumentFormat.
 * - s_documentFormatCode     In this case format is defined as URN codes (ex: urn:ihe:iti:xds-sd:text:2008, @see table "docFormats" in hl_getInteropCodes).
 * Note: if both i_documentFormat and s_documentFormatCode are employed, an error is returned.
 *
 * OUT
 *   "s_uniqueId"                   // Sent document unique id (to replace or remove it, etc.)
 *
 * @param {string}              ins
 * @param {string}              fileContentBase64         Document content in base64.
 * @param {string}              documentTitle             Title of the document.
 * @param {string}              documentDescription       Description of the document.
 * @param {string}              documentCategory          See specs section 9.1.5 "TABLE 5 CATEGORIES DE DOCUMENT (TYPECODE)".
 * @param {number}              documentVisibility        Normal=1. See enum DocumentVisibility.
 * @param {number or string}    documentFormat            If documentFormat is an integer : PlainText = 1, PdfApplication = 5, etc. Cf. enum DocumentFormat, if documentFormat is a string:  PlainText = urn:ihe:iti:xds-sd:text:2008, ... .
 * @param {string}              healthcareSetting         See specs section 9.1.8 "TABLE 8 - CADRE DE SOINS (HEALTHCARE SETTINGS)".
 * @param                       replacedDocumentUniqueId  Optional
 * @param                       submissionSetTitle        Optional.
 * @param                       submissionSetDescription  Optional.
 * @param                       EventCodes                Optional.
 * @param {object}              command                   Allow to extend the command for optionals parameters
 * @param {function}            resultCallback            The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_sendDocument = function( ins,
                                                 fileContentBase64,
                                                 documentTitle,
                                                 documentDescription,
                                                 documentCategory,
                                                 documentVisibility,
                                                 documentFormat,
                                                 healthcareSetting,
                                                 replacedDocumentUniqueId,
                                                 submissionSetTitle,
                                                 submissionSetDescription,
                                                 eventCodes,
                                                 command,
                                                 resultCallback)
{
    command = command || {};
    command.s_commandName               = "hl_sendDocument";

    // INS number can be given using the s_ins field:
    command.s_ins                      = ins;
    // Or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins ,
    //     "s_insType" : "N"
    // };
    command.s_fileContentBase64         = fileContentBase64;
    command.s_documentTitle             = documentTitle;
    command.s_documentDescription       = documentDescription;
    command.s_documentCategory          = documentCategory;
    command.i_documentVisibility        = documentVisibility;
    if( typeof documentFormat === "string" )
    {
        command.s_documentFormatCode        = documentFormat;
    }
    else
    {
        command.i_documentFormat            = documentFormat;
    }
    command.s_healthcareSetting         = healthcareSetting;
    if (eventCodes)                     command.s_eventCodes                = eventCodes;
    if (replacedDocumentUniqueId)       command.s_replacedDocumentUniqueId  = replacedDocumentUniqueId;
    if (submissionSetTitle)             command.s_submissionSetTitle        = submissionSetTitle;
    if (submissionSetDescription)       command.s_submissionSetDescription  = submissionSetDescription;
    command.s_sessionId                 = this.getSessionId();



    // May take a long time for big documents.
    return this.sendCommand(command, 200, resultCallback);
};

/**
  * Validation of the documents (document are only valided using CDA-XSD and schematrons).
  *     useful for development of CDA documents.
  *
  * IN
  * {
  *    Documents :
  *    [
  *          {
  *              s_contentInBase64
  *              s_title
  *              s_description
  *              s_category
  *              i_visibility
  *              i_format
  *              s_replacedDocumentUniqueId      (*)
  *              s_oid                           (*)
  *              EventCodes : []                 (*) // Array with event code objects.
  *              s_creationDate                  (*) // Format: YYYYMMDDhhmmss[+-]hhmm
  *              s_serviceStartDate              (*) // Formats: YYYYMMJJ, YYYYMMDDhhmm[+-]hhmm or YYYYMMDDhhmmss[+-]hhmm
  *              s_serviceStopDate               (*)
  *              Informants : [ { } ]            (*) // Array with informant objects.
  *              TreatingPhysician               (*) // Hp Structure.
  *              AdditionalAuthors : [ {} ]      (*) // Array of Hp Stucture
  *              Performer : {}                  (*) // Performer structure { Hp : {}, i_role }
  *           }
  *    ]
  *    s_ins
  *    s_healthcareSetting
  *    s_submissionSetTitle         (*)
  *    s_submissionSetDescription   (*)
  *    s_localPatientId             (*)
  *    i_transcodeTypeCode          (*) // If true (1), transform the value of "s_category" on each document to make sure the most up to date value is sent to the DMP servers. If false (0), an obsolete value of "s_category" will raise an error. Default Off (0)
  *    i_disablePdfa1Conversion"    (*) If true (1), PDF/A1 conversion is disabled.
  *    i_pdfa1IgnoreTransparency"   (*) If true (1), Transparency is ignored during PDF/A1 conversion.
  *    i_pdfa1ImageResolution       (*) Set maximum resolution (in DPI) for images during PDF/A1 conversion. If not set default resolution is employed (200 DPI).
  *    i_getCdaHeaders              (*) If true (1), CDA headers are retreived.
  *    i_getCda                     (*) If true (1), Full CDA is retreived.
  *    i_getContent                 (*) If true (1), Content of the document is retrieved. It is either the document if document is unstructured or the Document converted in HTML format if the document is structured.
  *    s_submissionSetOid           (*)
  * }
  * OUT
  * {
  *     Documents (d) :
  *     [
  *         { s_cdaHeadersInBase64 : "" (a) , s_cdaInBase64 : "" (b), s_contentInBase64 (c) }
  *     ]
  *
  * (a) Only present if i_getCdaHeaders is true (1).
  * (b) Only present if i_getCda        is true (1).
  * (c) Only present if i_getContent    is true (1).
  * (d) Only present if i_getCdaHeaders or i_getCda or i_getContent is true (1)
  * }
  */
DMPConnect.prototype.hl_validateDocuments = function( ins,
                                                      healthcareSetting,
                                                      submissionSetTitle,
                                                      submissionSetDescription,
                                                      localPatientId,
                                                      documents,
                                                      command,
                                                      resultCallback )
{
    command                 = command || {} ;
    command.s_commandName   = "hl_validateDocuments" ;
    command.s_sessionId     = this.getSessionId() ;

    command.s_ins = ins ;
    // Or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins ,
    //     "s_insType" : "N"
    // };
    command.s_healthcareSetting = healthcareSetting ;
    if( submissionSetTitle )
        command.s_submissionSetTitle = submissionSetTitle ;
    if( submissionSetDescription )
        command.s_submissionSetDescription = submissionSetDescription ;
    if( localPatientId )
        command.s_localPatientId = localPatientId ;

    command.Documents = documents ;

    return this.sendCommand( command, 200 , resultCallback ) ;
}

DMPConnect.prototype.hl_getCdaFromSubmissionSet = function( ins,
                                                            healthcareSetting,
                                                            submissionSetTitle,
                                                            submissionSetDescription,
                                                            localPatientId,
                                                            documents,
                                                            command,
                                                            resultCallback )
{
    command                 = command || {} ;
    command.s_commandName   = "hl_getCdaFromSubmissionSet" ;
    command.s_sessionId     = this.getSessionId() ;

    command.s_ins = ins ;
    // Or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins ,
    //     "s_insType" : "N"
    // };
    command.s_healthcareSetting = healthcareSetting ;
    if( submissionSetTitle )
        command.s_submissionSetTitle = submissionSetTitle ;
    if( submissionSetDescription )
        command.s_submissionSetDescription = submissionSetDescription ;
    if( localPatientId )
        command.s_localPatientId = localPatientId ;

    command.Documents = documents ;

    return this.sendCommand( command, 200 , resultCallback ) ;
}

/**
  *
  * IN:
  *
  * For each document, the following parameters are available:
  *
  * IN
  * {
  *    Documents :
  *    [
  *          {
  *              s_contentInBase64
  *              s_title
  *              s_description
  *              s_category
  *              i_visibility
  *              i_format
  *              s_replacedDocumentUniqueId      (*)
  *              s_versionNumber                 (*)
  *              s_setIdRoot                     (*)
  *              s_setIdExtension                (*)
  *              s_styleSheetInBase64            (*) // Document stylesheet (xsl).
  *              EventCodes : []                 (*) // Array with event code objects.
  *              s_creationDate                  (*) // Format: YYYYMMDDhhmmss[+-]hhmm
  *              s_serviceStartDate              (*) // Formats: YYYYMMJJ, YYYYMMDDhhmm[+-]hhmm or YYYYMMDDhhmmss[+-]hhmm
  *              s_serviceStopDate               (*)
  *              Informants : [ { } ]            (*) // Array with informant objects.
  *              TreatingPhysician               (*) // Hp Structure.
  *              AdditionalAuthors : [ {} ]      (*) // Array of Hp Stucture
  *              Performer : {}                  (*) // Performer structure { Hp : {}, i_role }
  *           }
  *    ]
  *    s_ins
  *    s_healthcareSetting
  *    s_submissionSetTitle         (*)
  *    s_submissionSetDescription   (*)
  *    s_localPatientId             (*)
  *    i_disablePdfa1Conversion     (*) // If true (1), PDF/A1 conversion is disabled.
  *    i_pdfa1IgnoreTransparency    (*) // If true (1), Transparency is ignored during PDF/A1 conversion.
  *    i_pdfa1ImageResolution       (*) // Set maximum resolution (in DPI) for images during PDF/A1 conversion. If not set default resolution is employed (200 DPI).
  *    i_transcodeTypeCode          (*) // If true (1), transform the value of "s_category" on each document to make sure the most up to date value is sent to the DMP servers. If false (0), an obsolete value of "s_category" will raise an error. Default Off (0)
  *    i_forceSchematronsValidation (*) // If true (1), enable schematrons validation for unstructured documents (default: off).
  * }
  * OUT
  * {
  *     UniqueIds : Array of unique IDs, one per document, in the same order of the document array.
  * }
  * (*) Optional arguments.
  */
DMPConnect.prototype.hl_sendDocuments = function( ins,
                                                  healthcareSetting,
                                                  submissionSetTitle,
                                                  submissionSetDescription,
                                                  localPatientId,
                                                  documents,
                                                  command,
                                                  resultCallback )
{
    command                 = command || {} ;
    command.s_commandName   = "hl_sendDocuments" ;
    command.s_sessionId     = this.getSessionId();

    command.s_ins = ins ;
    // Or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins ,
    //     "s_insType" : "N"
    // };
    command.s_healthcareSetting = healthcareSetting ;
    if( submissionSetTitle )
        command.s_submissionSetTitle = submissionSetTitle ;
    if( submissionSetDescription )
        command.s_submissionSetDescription = submissionSetDescription ;
    if( localPatientId )
        command.s_localPatientId = localPatientId ;

    command.Documents = documents ;

    return this.sendCommand( command, 200 , resultCallback ) ;
}

/**
  * @brief Perform TD3.1b transaction.
  *
  * IN
  * {
  *   s_ins
  *   s_documentUniqueId
  * }
  * OUT
  * {
  *   s_documentUuid
  * }
  */
DMPConnect.prototype.hl_getDocumentUuidByUniqueId = function( documentUniqueId, ins, command, resultCallback )
{
    command               = command || {};
    command.s_commandName = "hl_getDocumentUuidByUniqueId" ;
    command.s_sessionId   = this.getSessionId();

    command.s_ins = ins ;
    // Or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins"     : ins ,
    //     "s_insType" : "N"
    // };
    command.s_documentUniqueId = documentUniqueId;

    return this.sendCommand( command, 30, resultCallback );
}


/**
 * @brief TD 1.3a Get DMP administrative data.
 *
 * hl_createDmpConnector must have been called.
 *
 * IN
 *   "s_ins"
 * OUT
 *   DmpAdministrativeData_t structure. See C headers.
 *
 *   Example of answer:
 *   {
 *     "GuardianData" :
 *     {
 *       "CivilStatus" :
 *       {
 *         "i_gender" : 1
 *         "s_given" : paul
 *         "s_name" : lucien
 *       }
 *
 *       "ContactDetails" :
 *       {
 *         "s_address" :
 *         "s_addressComplement" :
 *         "s_city" :
 *         "s_landlinePhone" :
 *         "s_mail" :
 *         "s_mobilePhone" :
 *         "s_postalCode" :
 *       }
 *
 *       "i_role" : 2
 *     }
 *
 *     "PatientData" :
 *     {
 *       "ExtendedCivilStatus" :
 *       {
 *         "CivilStatus" :
 *         {
 *           "i_gender" : 3
 *           "s_given" : NATHALIE
 *           "s_name" : DESMAUX
 *         }
 *
 *         "i_sex" : 3
 *         "s_birthCountry" :
 *         "s_birthDay" : 19550614
 *         "s_birthName" : DESMAUX
 *       }
 *
 *       "ExtendedContactDetails" :
 *       {
 *         "ContactDetails" :
 *         {
 *           "s_address" : 5 BD ALEXANDRE OYON
 *           "s_addressComplement" : APARTEMENT 50
 *           "s_city" : MARSEILLE
 *           "s_landlinePhone" :
 *           "s_mail" :
 *           "s_mobilePhone" : 0687820756
 *           "s_postalCode" : 13000
 *         }
 *
 *         "s_country" : FRANCE
 *       }
 *
 *       "Ins" :
 *       {
 *         "s_ins" : 0448685716413283718907
 *         "s_insType" : C
 *       }
 *
 *     }
 *
 *     "PatientOppositions" :
 *     {
 *       "i_brisDeGlaceOpposition" : 0
 *       "i_centre15Opposition" : 0
 *     }
 *
 *     "i_guardian" : 2
 *   }
 *
 * @param {string}                  ins
 * @param {object}   command        Allow to extend the command for optionals parameters
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getDmpAdministrativeData = function(ins, command, resultCallback)
{
    command = command || {};
    command.s_commandName = "hl_getDmpAdministrativeData";

    // Use either s_ins field of Ins structure :
    /*
    command.Ins =
    {
         s_ins : ins ,
         s_insType : "N"
    };
    */
    command.s_ins        = ins;


    command.s_sessionId   = this.getSessionId();

    return this.sendCommand(command, 10, resultCallback);
};


/**
 * @brief TD 1.3b Update DMP administrative data.
 *
 * hl_createDmpConnector must have been called.
 *
 * IN
 *   DmpAdministrativeData_t structure. See C headers. (same format as the output of hl_getDmpAdministrativeData)
 *
 *   Example of input
 *   {
 *     "GuardianData" :
 *     {
 *       "CivilStatus" :
 *       {
 *         "i_gender" : 1
 *         "s_given" : paul
 *         "s_name" : lucien
 *       }
 *
 *       "ContactDetails" :
 *       {
 *         "s_address" :
 *         "s_addressComplement" :
 *         "s_city" :
 *         "s_landlinePhone" :
 *         "s_mail" :
 *         "s_mobilePhone" :
 *         "s_postalCode" :
 *       }
 *
 *       "i_role" : 2
 *     }
 *
 *     "PatientData" :
 *     {
 *       "ExtendedCivilStatus" :
 *       {
 *         "CivilStatus" :
 *         {
 *           "i_gender" : 3
 *           "s_given" : NATHALIE
 *           "s_name" : DESMAUX
 *         }
 *
 *         "i_sex" : 3
 *         "s_birthCountry" :
 *         "s_birthDay" : 19550614
 *         "s_birthName" : DESMAUX
 *       }
 *
 *       "ExtendedContactDetails" :
 *       {
 *         "ContactDetails" :
 *         {
 *           "s_address" : 5 BD ALEXANDRE OYON
 *           "s_addressComplement" : APARTEMENT 50
 *           "s_city" : MARSEILLE
 *           "s_landlinePhone" :
 *           "s_mail" :
 *           "s_mobilePhone" : 0687820756
 *           "s_postalCode" : 13000
 *         }
 *
 *         "s_country" : FRANCE
 *       }
 *
 *       "Ins" :
 *       {
 *         "s_ins" : 0448685716413283718907
 *         "s_insType" : C
 *       }
 *
 *     }
 *
 *     "PatientOppositions" :
 *     {
 *       "i_brisDeGlaceOpposition" : 0
 *       "i_centre15Opposition" : 0
 *     }
 *
 *     "i_guardian" : 2
 *   }
 * OUT
 *
 * @param {string}   dmpAdminData The administrative patient data structure
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_updateDmpAdministrativeData = function( dmpAdminData , resultCallback )
{
    var command =
    {
        "DmpAdministrativeData" : dmpAdminData,
        "s_commandName"         : "hl_updateDmpAdministrativeData",
        "s_sessionId"           : this.getSessionId()
    };

    return this.sendCommand(command, 10, resultCallback);
}

DMPConnect.Sex =
{
    UnknownSex : 1,
    Male       : 2,
    Female     : 3
};

DMPConnect.Gender =
{
    UnknownGender : 1,
    M             : 2,
    Mme           : 3
};

DMPConnect.GuardianStatus =
{
    NoGuardianDefined : 1,
    GuardianDefined   : 2
};

DMPConnect.GuardianAction =
{
    IgnoreGuardianSetup : 1,
    AddGuardian         : 2,
    RemoveGuardian      : 3
};

DMPConnect.LegalRepresentantRole =
{
    Father           :  1,
    Mother           :  2,
    StepFather       :  3,
    StepMother       :  4,
    GrandFather      :  5,
    GrandMother      :  6,
    GrandGrandFather :  7,
    GrandGrandMother :  8,
    Aunt             :  9,
    Uncle            : 10,
    Brother          : 11,
    Sister           : 12,
    Guardian         : 13
};



/**
 * @brief TD 0.5 Find DMP based on partial information.
 *
 * hl_createDmpConnector must have been called.
 *
 * See specs for the maximum size of each string field.
 *
 *  IN: M = Mandatory data.
 *  {
 *     s_name       [81] Part of the patient name. At least 2 characters.
 *     s_givenName  [61] Patient given name.
 *     s_birthday   [ 9] Patient birthday as YYYYMMDD.
 *     s_city       [39] City.
 *     s_postalCode [11] Postal code.
 *     i_sex             Sex enum.
 *   M i_approxName      Boolean
 *   M i_approxCity      Boolean
 *  }
 *  OUT
 *
 * @param {object}   command        The input parameters.
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_findPatients = function( command          ,
                                                 resultCallback   )
{
    command = command || {};
    command.s_commandName = "hl_findPatients";
    command.s_sessionId   = this.getSessionId();

    return this.sendCommand( command, 10, resultCallback );
};

/**
 * @brief TD 1.4 Close a Dmp.
 *
 * hl_createDmpConnector must have been called.
 *
 * See specs for the maximum size of each string field.
 *
 *  IN:
 *    "s_ins"
 *    "s_reason"
 *    "s_name"
 *    "s_given"
 * OUT
 *
 * @param {string}   ins
 * @param {string}   reason
 * @param {string}   name
 * @param {string}   given
 *
 * @param {object}   command        Allow to extend the command for optionals parameters
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_closeDmp = function( ins              ,
                                             reason           ,
                                             name             ,
                                             given            ,

                                             command          ,
                                             resultCallback   )
{
    command = command || {};
    command.s_commandName       = "hl_closeDmp";

    // INS can be given using s_ins field :
    command.s_ins              = ins  ;
    // Or using Ins structure
    // command.Ins =
    // {
    //     s_ins : ins ,
    //     s_insType : "N"
    // };

    command.s_reason            = reason;
    command.s_name              = name  ;
    command.s_given             = given ;

    command.s_sessionId         = this.getSessionId();

    return this.sendCommand(command, 10, resultCallback);
};

DMPConnect.AccessMode =
{
    NormalAccess : 1,
    BrisDeGlace  : 2,
    Centre15     : 3,
};

/**
 * @brief Set the DmpConnector access mode.
 *        See specifications, function setDmpConnectorDirectAuthenticationDmpAccessMode().
 * IN (M = Mandatory)
 * {
 *  M i_accessMode : Cf. DMPConnect.AccessMode enum.
 *  M s_reason     : For the "Bris de Glace" mode, holds the description of the reason for opening a DMP in this mode.
 * }
 * OUT
 *
 * @param {number}   accessMode Cf. DMPConnect.AccessMode enum.
 * @param {string}   reason     For the "Bris de Glace" mode, holds the description of the reason for opening a DMP in this mode.
 *
 * @param {object}   command        Allow to extend the command for optionals parameters (none currently)
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_setDmpAccessMode = function( accessMode, reason, command, resultCallback )
{
    command = command || {};
    command.s_commandName = "hl_setDmpAccessMode";
    command.i_accessMode  = accessMode;
    command.s_reason      = reason;
    command.s_sessionId   = this.getSessionId();

    return this.sendCommand( command, 2, resultCallback );
};

/**
 * Values for OTP Channel type.
 */
DMPConnect.OTPChannelType =
{
    SMS  : 1,
    Mail : 2
};

/**
  * @brief Get OTP Channel value.
  *
  * If web access does not exists or given channel is not present, an error is returned.
  *
  * IN
  * {
  *     s_ins
  *     i_otpChannelType            1 for SMS, 2 for Mail
  * }
  * OUT
  * {
  *     s_otpChannelValue
  * }
  *
  * User must have an autorization access on the given DMP.
  *
  * @param {string} ins             INS of the patient.
  * @param {int} otpChannelType     Type of the channel to query. 1 for SMS ; 2 for Mail.
  * @param {function} resultCallback The callback that take the result from sendCommand
  */
DMPConnect.prototype.hl_getOtpChannelValue = function( ins, type, resultCallback )
{
    var command = {};

    command.s_commandName          = "hl_getOtpChannelValue";
    command.s_ins                  = ins;
    command.i_otpChannelType       = type;
    command.s_sessionId            = this.getSessionId();

    return this.sendCommand( command, 20, resultCallback );
};

/**
 * Set or reset the patient PDF form.
 *
 * If no channel is given, the function will try to reset the patient credentials.
 * It can fail if no OTP was defined in the past.
 *
 * The function must be called with either one of the channel defined, or none of them.
 *
 * Set the channel value to 'remove' to delete it, but please note that:
 * - One channel must remain defined.
 * - It is not possible to know if a channel is defined.
 *
 * IN * = optional
 * {
 *    s_ins
 * *  s_otpPhone :
 *   OR
 * *  s_otpEmail : set to 'remove' to delete it. It is impossible to remove both channels.
 * *  i_returnAsFile : if it is set to 1, the pdf file stored on the system (in a temporary directory) is returned.
 * }
 * OUT
 * {
 *    s_pdfFileContentInBase64
 *    s_pdfFileUrl : returned if i_returnAsFile is set to 1
 * }
 *
 * @param {string}   ins            INS of the patient.
 * @param {string}   otpPhone       Optional.
 * @param {string}   otpEmail       Optional.
 * @param {integer}  returnAsFile   Optional. Set to 1 to keep the generated pdf on the client temporary directory.
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getPatientWebAccessPdf = function( ins, otpPhone, otpEmail, returnAsFile, openPdf, resultCallback )
{
    otpPhone     = otpPhone     || "";
    otpEmail     = otpEmail     || "";
    returnAsFile = returnAsFile || 0;

    var command = {};
    command.s_commandName          = "hl_getPatientWebAccessPdf";
    command.s_ins                  = ins;
    command.i_openPdfAfterCreation = openPdf;

    if( otpPhone.length )
        command.s_otpPhone = otpPhone;

    if( otpEmail.length )
        command.s_otpEmail = otpEmail;

    command.i_returnAsFile = returnAsFile;

    command.s_sessionId    = this.getSessionId();

    return this.sendCommand( command, 20, resultCallback );
};


/**
  * Create Web Access for the patient.
  *
  *
  * IN
  * {
  *         s_ins                       INS of the patient.
  *     (*) s_otpPhone                  OTP value for SMS channel.
  *     (*) s_otpEmail                  OTP value for Email channel.
  *     (*) i_openPdfAfterCreation      if = 1, try to open the file with the system default pdf viewer.
  *     (*) i_returnAsFile              if = 1, the pdf file stored on the system (in a temporary directory) is returned.
  * }
  * OUT
  * {
  *        s_pdfFileContentInBase64  Access creation PDF.
  *    (*) s_pdfFileUrl              returned if i_returnAsFile is set to 1
  * }
  *
  * (*) Optional.
  *
  *
  * Notes:
  * - if WebAccess already exists an error is returned.
  * - If both s_otpPhone and s_otpEmail are defined, web access is created using SMS channel then Mail channel is added.
  */
DMPConnect.prototype.hl_createWebAccess = function( ins, otpPhone, otpEmail, returnAsFile, openPdf, resultCallback )
{
    otpPhone     = otpPhone     || "";
    otpEmail     = otpEmail     || "";
    returnAsFile = returnAsFile || 0;

    var command = {};
    command.s_commandName          = "hl_createWebAccess";
    command.s_ins                  = ins;
    command.i_openPdfAfterCreation = openPdf;
    command.s_sessionId            = this.getSessionId();

    if( otpPhone.length )
        command.s_otpPhone = otpPhone;

    if( otpEmail.length )
        command.s_otpEmail = otpEmail;

    command.i_returnAsFile = returnAsFile;

    return this.sendCommand( command, 20, resultCallback );
}

/**
  * Add a channel to the Web Access of the patient.
  *
  * IN
  * {
  *         s_ins                       INS of the patient.
  *     (*) s_otpPhone                  OTP value for SMS channel.
  *     (*) s_otpEmail                  OTP value for Email channel.
  * }
  * OUT
  * {
  *
  * }
  *
  * Notes:
  * - Web Access must exists (if not use hl_createWebAccess).
  * - Only one channel could be added.
  * - If channel already exists an error is returned (to update value use hl_updateWebAccessChannel)
  */
DMPConnect.prototype.hl_addWebAccessChannel = function( ins, otpPhone, otpEmail, resultCallback )
{
    otpPhone     = otpPhone     || "";
    otpEmail     = otpEmail     || "";

    var command = {};
    command.s_commandName          = "hl_addWebAccessChannel";
    command.s_ins                  = ins;
    command.s_sessionId            = this.getSessionId();

    if( otpPhone.length )
        command.s_otpPhone = otpPhone;

    if( otpEmail.length )
        command.s_otpEmail = otpEmail;

    return this.sendCommand( command, 20, resultCallback );
}

/**
  * Update OTP channel(s) value(s).
  *
  * IN
  * {
  *         s_ins                       INS of the patient.
  *     (*) s_otpPhone                  OTP value for SMS channel.
  *     (*) s_otpEmail                  OTP value for Email channel.
  * }
  * OUT
  * {
  *
  * }
  *
  * Notes:
  * - Web Access must exists (if not use hl_createWebAccess).
  * - Channel must exist (if not use hl_addWebAccessChannel).
  * - Both channels could be updated, the order of the update is SMS then Mail.
  */
DMPConnect.prototype.hl_updateWebAccessChannel = function( ins, otpPhone, otpEmail, resultCallback )
{
    otpPhone     = otpPhone     || "";
    otpEmail     = otpEmail     || "";

    var command = {};
    command.s_commandName          = "hl_updateWebAccessChannel";
    command.s_ins                  = ins;
    command.s_sessionId            = this.getSessionId();

    if( otpPhone.length )
        command.s_otpPhone = otpPhone;

    if( otpEmail.length )
        command.s_otpEmail = otpEmail;

    return this.sendCommand( command, 20, resultCallback );
}

/**
 * TD 0.9: Get the Web PS DMP Urls for a patient.
 * IN * = optional
 * {
 *    s_ins
 * }
 * OUT
 * {
 *    Urls : [
 *       "url of TableauDeBord",
 *       "url of DossierPatient",
 *       "url of GestionDMPPatient",
 *       "url of Documents",
 *       "url of ParcoursDeSoins",
 *       "url of HistoriqueAcces",
 *       "url of Parametrages",
 *       "url of VolontesEtDroits",
 *       "url of CarnetDeVaccination",
 *    ]
 * }
 *
 * @param {string}   ins            INS of the patient.
 * @param {function} resultCallback The callback that take the result from sendCommand
 */
DMPConnect.prototype.hl_getWebPsDmpUrls = function( ins, resultCallback )
{
    var command = {};
    command.s_commandName  = "hl_getWebPsDmpUrls";
    // INS can be given using the s_ins field :
    command.s_ins         = ins;
    // or using the Ins structure:
    // command.Ins =
    // {
    //     "s_ins" : ins ,
    //     "s_insType" : "N"
    // } ;

    command.s_sessionId    = this.getSessionId();

    return this.sendCommand( command, 2, resultCallback );
};

/**
  * @brief Set custom webPS root URL.
  *
  * IN
  * {
  *     s_url
  * }
  * OUT
  * {
  *
  * }
  *
  * @param {string} url                 New root URL for the WebPS urls. Set it to an empty string to reset to the default value.
  * @param {function} resultCallback    The callback that take the result from sendCommand
  */
DMPConnect.prototype.hl_setWebPsRootUrl = function( url, resultCallback )
{
    var command = {};
    command.s_commandName = "hl_setWebPsRootUrl";
    command.s_url         = url;

    command.s_sessionId = this.getSessionId();

    return this.sendCommand( command, 2 , resultCallback );
}


/**
 * @brief Query the DMP parameters to get the majority age
 *
 * IN
 * {
 *
 * }
 * OUT
 * {
 *    i_majorityAge
 * }
 */
DMPConnect.prototype.hl_getMajorityAge = function( resultCallback )
{
    var command = {};
    command.s_commandName = "hl_getMajorityAge" ;
    command.s_sessionId   = this.getSessionId() ;

    return this.sendCommand( command , 20 , resultCallback ) ;
}

/**
 * @brief Set confidentiality level for DMP transactions.
 *
 * @param {int} aConfidentialityEnabler     Set to 1 to enable secret connection, set to 0 to disable it.
 */
DMPConnect.prototype.hl_setConfidentialityLevel = function( aConfidentialityEnabler, resultCallback )
{
    var command = {};
    command.s_commandName            = "hl_setConfidentialityLevel";
    command.s_sessionId              = this.getSessionId();
    command.i_enableSecretConnection = aConfidentialityEnabler;

    return this.sendCommand(command, 15, resultCallback );
}

DMPConnect.DmpAuthorizationListType =
{
    AllAuthorizations: 1,
    AuthorizedUsers: 2,
    DeniedUsers: 3
};

/**
 * @brief Get HP authorization list for a DMP.
 *
 * @param {string} anIns                    INS of the DMP to test.
 * @param {int} anAuthorizationListType     Authorization list type (using values in DMPConnect.DmpAuthorizationListType)
 *
 * IN
 * {
 *    "s_ins"
 *    "i_authorizationType" (*)         // Default value is 1.
 * }
 * OUT
 * {
 *      AuthorizationList :
 *      [
 *          {
 *              s_nationalId            // National Identifier.
 *              s_nationalIdType        // Hp national identifier type.
 *              s_name                  // Hp name.
 *              s_given                 // Hp given.
 *              s_specialityCode        // Hp speciality code. Eg. "G15_10/SM36".
 *              s_speciality            // Hp speciality display. Eg. "Oncologie option médicale (SM)".
 *              s_authorizationStart    // Hp authorization start date (format YYYYMMDDHHmmss).
 *              s_lastActionDate        // Hp last action date (format YYYYMMDDHHmmss).
 *              i_authorizationType     // Hp authorization type. Values in enum HpAuthorizationType (ie: 1 => AuthorizedHp, 2 => DeniedHp).
 *              i_generalPractitionner  // Set to 1 if physician is "médecin traitant".
 *          }
 *          ,
 *      ]
 * }
 */
DMPConnect.prototype.hl_getDmpAuthorizationsList = function( anIns, anAuthorizationListType, resultCallback )
{
    var command = {};
    command.s_commandName           = "hl_getDmpAuthorizationsList" ;
    command.s_sessionId             = this.getSessionId();
    command.s_ins                   = anIns;
    command.i_authorizationType     = anAuthorizationListType;

    return this.sendCommand(command, 30, resultCallback);
}

/**
  * @brief Get DMP server parameters.
  *
  * IN
  * {
  *
  * }
  * OUT
  * {
  *     i_hrMaxSearchPeriod         // (month).
  *     i_majorityAge               // (year).
  *     i_minorFunctionManagement   // (bool: 1/0)
  *     i_cumulMasquePsPatient      // (bool: 1/0)
  * }
  */
DMPConnect.prototype.hl_getDmpParameters = function( resultCallback )
{
    var command = {};
    command.s_commandName           = "hl_getDmpParameters" ;
    command.s_sessionId             = this.getSessionId();

    return this.sendCommand( command, 30, resultCallback );
}
