define("comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/allergy-titled-box/index", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkToForc
    @feature="medical-history"
    @component="edition-form"
    @patient={{@patient}}
  >
    <TitledBox
      @breakContent={{true}}
      @lightContent={{true}}
      data-test-titled-box="allergy"
    >
      <:title>
        Allergies
      </:title>
      <:content>
        {{#if this.allergies}}
          {{this.allergies}}
        {{else}}
          {{t "dashboard.medical-synthesis.allergies-default-message"}}
        {{/if}}
      </:content>
    </TitledBox>
  </LinkToForc>
  */
  {
    "id": "KeuWHHV3",
    "block": "[[[8,[39,0],null,[[\"@feature\",\"@component\",\"@patient\"],[\"medical-history\",\"edition-form\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,\"data-test-titled-box\",\"allergy\"]],[[\"@breakContent\",\"@lightContent\"],[true,true]],[[\"title\",\"content\"],[[[[1,\"\\n      Allergies\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"allergies\"]],[[[1,\"        \"],[1,[30,0,[\"allergies\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"dashboard.medical-synthesis.allergies-default-message\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@patient\"],false,[\"link-to-forc\",\"titled-box\",\"if\",\"t\"]]",
    "moduleName": "comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/allergy-titled-box/index.hbs",
    "isStrictMode": false
  });
  class AllergyTitledBoxComponent extends _component2.default {
    get allergies() {
      var _this$args$patient$co;
      return (_this$args$patient$co = this.args.patient.comorbiditiesAndAllergiesChangedJournalEvent) === null || _this$args$patient$co === void 0 ? void 0 : _this$args$patient$co.medicatedAllergies;
    }
  }
  _exports.default = AllergyTitledBoxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AllergyTitledBoxComponent);
});