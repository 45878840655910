define("comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/comorbidity-titled-box/index", ["exports", "@ember/component", "@ember/service", "@glimmer/component", "core/utils/t-with-default", "@ember/template-factory"], function (_exports, _component, _service, _component2, _tWithDefault, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkToForc
    @feature="medical-history"
    @component="edition-form"
    @patient={{@patient}}
  >
    <TitledBox
      @lightContent={{true}}
      @breakContent={{true}}
      data-test-titled-box="comorbidity"
    >
      <:title>
        Comorbidités
      </:title>
      <:content>
        {{#if this.comorbidities}}
          {{this.comorbidities}}
        {{else}}
          {{t "dashboard.medical-synthesis.pathologies-default-message"}}
        {{/if}}
      </:content>
    </TitledBox>
  </LinkToForc>
  */
  {
    "id": "ot714qjC",
    "block": "[[[8,[39,0],null,[[\"@feature\",\"@component\",\"@patient\"],[\"medical-history\",\"edition-form\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,\"data-test-titled-box\",\"comorbidity\"]],[[\"@lightContent\",\"@breakContent\"],[true,true]],[[\"title\",\"content\"],[[[[1,\"\\n      Comorbidités\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"comorbidities\"]],[[[1,\"        \"],[1,[30,0,[\"comorbidities\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"dashboard.medical-synthesis.pathologies-default-message\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@patient\"],false,[\"link-to-forc\",\"titled-box\",\"if\",\"t\"]]",
    "moduleName": "comorbidity/components/comorbidity/comorbidity-and-allergy-dashboard-row/comorbidity-titled-box/index.hbs",
    "isStrictMode": false
  });
  let ComorbidityTitledBoxComponent = _exports.default = (_class = class ComorbidityTitledBoxComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get comorbidities() {
      var _this$args$patient$co;
      return (_this$args$patient$co = this.args.patient.comorbiditiesAndAllergiesChangedJournalEvent) === null || _this$args$patient$co === void 0 || (_this$args$patient$co = _this$args$patient$co.comorbidities) === null || _this$args$patient$co === void 0 ? void 0 : _this$args$patient$co.map(code => (0, _tWithDefault.default)(this.intl, "comorbidity.icd10.".concat(code), code)).join(", ");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ComorbidityTitledBoxComponent);
});